import React from "react";
import {useDispatch, useSelector} from "react-redux";
// import { Navigate, Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/index.css";
import {getUserData} from "./helpers/Utils";
import AuthLayout from "./layouts/AuthLayout";
import DashboardLayout from "./layouts/DashboardLayout";
// import SignIn from "./modules/auth/SignIn";
import {setUserPermissionsReducer, updateAuthUser} from "./redux/reducers";
import InfoView from "./shared/ui/InfoView";

function App() {
    const dispatch = useDispatch();
    const {isLogin} = useSelector(({AuthUserReducer}) => AuthUserReducer);

    React.useEffect(() => {
        // var hours = 8; // to clear the localStorage after 1 hour
        // // (if someone want to clear after 8hrs simply change hours=8)
        // var now = new Date().getTime();
        // var setupTime = localStorage.getItem("setupTime");
        // if (setupTime == null) {
        //   localStorage.setItem("setupTime", now);
        // } else {
        //   if (now - setupTime > hours * 60 * 60 * 1000) {
        //     localStorage.clear();
        //     localStorage.setItem("setupTime", now);
        //   }
        // }

        const userData = getUserData();

        if (userData) {
            dispatch(updateAuthUser({data: userData, isLogin: true}));
            dispatch(setUserPermissionsReducer(userData));
        }
    }, []);

    return (
        <>
            {isLogin ? <DashboardLayout/> : <AuthLayout/>}
            <InfoView/>
        </>
    );
}

export default App;
