import { Form } from "react-bootstrap";
import React from "react";
import { FieldArray, useField } from "formik";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";

import ButtonComponent from "../../../../../shared/ui/Button";
import DropDown from "../../../../../shared/ui/DropDown";
import InputField from "../../../../../shared/ui/InputField";
import LabelComponent from "../../../../../shared/ui/Label";
import MultiSelect from "../../../../../shared/ui/MultiSelect";
import DeleteIcon from "../../../../../assets/images/objective/add-objective/delete-icon.png";
import DownloadIcon from "../../../../../assets/images/objective/add-objective/download-icon.png";
import EditIcon from "../../../../../assets/images/objective/add-objective/edit-icon.png";
import ExpandIcon from "../../../../../assets/images/objective/add-objective/expand-icon.png";
import CollapseIcon from "../../../../../assets/images/objective/add-objective/collapse-icon.png";
import { colorArray } from "../../../../../helpers/Utils";
import CustomToolTip from "../../../../../shared/ui/Tooltip";

export const FieldToolTipInput = (props) => {
  const [field, meta] = useField(props);
  const errors = meta.error && meta.touched && meta.error;
  return (
    <Form.Group className="mb-4">
      <CustomToolTip value={props.value}>
        <span>
          <InputField {...field} {...props} errors={errors} />
        </span>
      </CustomToolTip>
    </Form.Group>
  );
};
export const FieldInput = (props) => {
  const [field, meta] = useField(props);
  const errors = meta.error && meta.touched && meta.error;
  return (
    <Form.Group className="mb-4">
      <InputField {...field} {...props} errors={errors} />
    </Form.Group>
  );
};

export const FieldMultiSelect = (props) => {
  const [field, meta] = useField(props);
  const errors = meta.error && meta.touched && meta.error;
  return <MultiSelect {...props} {...field} errors={errors} />;
};

export const FieldDropDown = (props) => {
  const [field, meta] = useField(props);
  const errors = meta.error && meta.touched && meta.error;
  return <DropDown {...field} {...props} errors={errors} />;
};

export default function KeyResult({
  editData,
  values,
  setFieldValue,
  errors,
  touched,
  ownersList,
  objCycleList,
}) {
  const {
    userPermissions: { userType },
    user,
  } = useSelector(({ AuthUserReducer }) => AuthUserReducer);
  const defaultKeyResults = {
    keyResult: "",
    owners: userType === "individual" ? [{ user_id: user.user_id }] : [],
    quantification: "",
    startValue: "",
    endValue: "",
    customKeyResult: [
      {
        startValue: "",
        endValue: "",
        quantification: "",
      },
    ],
    showCustomResults: false,
    isEditable: true,
    isExpanded: true,
  };
  const handleDeleteKeyResult = (index) => {
    const tempKey = [...values.keyResults];
    if (tempKey.length - 1 === index && tempKey[tempKey.length - 2]) {
      tempKey[tempKey.length - 2].isEditable = true;
    }
    tempKey.splice(index, 1);
    setFieldValue(`keyResults`, tempKey);
  };
  const handleKeyResultChange = (e, index) => {
    setFieldValue(e.target.name, e.target.value);
  };

  const onSelect = (data, index) => {
    setFieldValue(`keyResults[${index}].owners`, data);
    if (
      values.keyResults[index].customKeyResult.length &&
      !values.keyResults[index].customKeyResult[0].owner
    ) {
      setFieldValue(`keyResults[${index}].customKeyResult`, []);
    }
    if (
      values.keyResults[index].customKeyResult.length &&
      values.keyResults[index].customKeyResult.length < data.length
    ) {
      if (!values.keyResults[index].customKeyResult[0].owner) {
        const tempKey = data.map((dta, i) => ({
          ownerId: dta.value,
          owner: dta.label,
          ownerImage: dta.ownerImage,
          desc: values.keyResults[index].keyResult,
          startValue:
            values.keyResults[index].customKeyResult[i]?.startValue || "",
          endValue: values.keyResults[index].customKeyResult[i]?.endValue || "",
          quantification:
            values.keyResults[index].customKeyResult[i]?.quantification || "",
        }));

        setFieldValue(`keyResults[${index}].customKeyResult`, tempKey);
      } else {
        const tempKey = data
          .slice(values.keyResults[index].customKeyResult.length)
          .map((dta, i) => ({
            ownerId: dta.value,
            owner: dta.label,
            ownerImage: dta.ownerImage,
            desc: values.keyResults[index].keyResult,
            startValue: "",
            endValue: "",
            quantification: "",
          }));

        const CustomKeyResult = [
          ...values.keyResults[index].customKeyResult,
          ...tempKey,
        ];
        setFieldValue(`keyResults[${index}].customKeyResult`, CustomKeyResult);
      }
    } else if (
      values.keyResults[index].customKeyResult.length &&
      values.keyResults[index].customKeyResult.length > data.length
    ) {
      const CustomKeyResult = values.keyResults[index].customKeyResult.slice(
        0,
        data.length
      );
      setFieldValue(
        `keyResults[${index}].customKeyResult`,
        CustomKeyResult.length
          ? CustomKeyResult
          : [
              {
                startValue: "",
                endValue: "",
                quantification: "",
              },
            ]
      );
    } else if (
      values.keyResults[index].customKeyResult.length &&
      values.keyResults[index].customKeyResult.length == data.length &&
      values.keyResults[index].customKeyResult[0]?.owner
    ) {
      const CustomKeyResult = values.keyResults[index].customKeyResult;
      setFieldValue(`keyResults[${index}].customKeyResult`, CustomKeyResult);
    } else {
      const customKeyResultData = data.length
        ? data.map((dta, i) => ({
            ownerId: dta.value,
            owner: dta.label,
            ownerImage: dta.ownerImage,
            desc: values.keyResults[index].keyResult,
            startValue: "",
            endValue: "",
            quantification: "",
          }))
        : [
            {
              startValue: "",
              endValue: "",
              quantification: "",
            },
          ];
      setFieldValue(
        `keyResults[${index}].customKeyResult`,
        customKeyResultData
      );
    }
  };

  const handleAddCustomResults = (index) => {
    setFieldValue(`keyResults[${index}].showCustomResults`, true);
  };

  const handleEditKeyResult = (index) => {
    setFieldValue(`keyResults[${index}].isEditable`, true);
  };

  const handleExpandKeyResult = (index) => {
    setFieldValue(
      `keyResults[${index}].isExpanded`,
      !values.keyResults[index].isExpanded
    );
  };

  const handleCustomKeyResultChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  const handleAddKeyResult = (arrayHelpers) => {
    const tempKeyResult = [...values.keyResults];
    tempKeyResult.push(defaultKeyResults);
    if (tempKeyResult[tempKeyResult.length - 2])
      tempKeyResult[tempKeyResult.length - 2].isEditable = false;
    setFieldValue(`keyResults`, tempKeyResult);
  };

  const handleStartValueChange = (e, index, i) => {
    if (
      values.keyResults[index].customKeyResult[i].quantification == "Percentage"
    ) {
      if (e.target.value > 100) setFieldValue(e.target.name, 100);
      else setFieldValue(e.target.name, e.target.value);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  const handleEndValueChange = (e, index, i) => {
    if (
      values.keyResults[index].customKeyResult[i].quantification == "Percentage"
    ) {
      if (e.target.value > 100) {
        setFieldValue(e.target.name, 100);
      } else {
        setFieldValue(e.target.name, e.target.value);
      }
    } else setFieldValue(e.target.name, e.target.value);
  };

  const handleQuantificationChange = (e, index, i) => {
    setFieldValue(e.target.name, e.target.value);
    if (e.target.value == "Percentage") {
      if (values.keyResults[index].customKeyResult[i].startValue > 100) {
        setFieldValue(
          `keyResults[${index}].customKeyResult[${i}].startValue`,
          100
        );
      }
      if (values.keyResults[index].customKeyResult[i].endValue > 100) {
        setFieldValue(
          `keyResults[${index}].customKeyResult[${i}].endValue`,
          100
        );
      }
    }
  };
  return (
    <>
      <FieldArray
        name="keyResults"
        render={(arrayHelpers) => (
          <>
            {values.keyResults &&
              values.keyResults.map((keyResult, index) => (
                <div className="key-results mb-3" key={index}>
                  <Form.Label className="label-class d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                      <span className="index-class me-3">{index + 1}</span>What
                      is the key result?
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        role="button"
                        style={
                          values.keyResults.length === 1
                            ? {
                                pointerEvents: "none",
                                opacity: "0.3",
                              }
                            : { color: "none" }
                        }
                        src={DeleteIcon}
                        alt="delete-icon"
                        className="me-4"
                        onClick={() => handleDeleteKeyResult(index)}
                      />
                      {index !== values.keyResults.length - 1 ? (
                        <>
                          <img
                            role="button"
                            src={EditIcon}
                            alt="edit-icon"
                            className="me-4"
                            onClick={() => handleEditKeyResult(index)}
                          />

                          <span
                            style={
                              keyResult.customKeyResult.length <= 1
                                ? {
                                    pointerEvents: "none",
                                    opacity: "0.3",
                                  }
                                : { color: "none" }
                            }
                          >
                            {keyResult.isExpanded ? (
                              <img
                                src={ExpandIcon}
                                alt="expand-icon"
                                role="button"
                                onClick={() => handleExpandKeyResult(index)}
                              />
                            ) : (
                              <img
                                src={CollapseIcon}
                                alt="collapse-icon"
                                role="button"
                                onClick={() => handleExpandKeyResult(index)}
                              />
                            )}
                          </span>
                        </>
                      ) : (
                        <img
                          role="button"
                          src={DownloadIcon}
                          alt="download-icon"
                        />
                      )}
                    </div>
                  </Form.Label>
                  <div className={`${!keyResult.isEditable && "disabled-div"}`}>
                    <FieldInput
                      type="text"
                      key={index}
                      name={`keyResults[${index}].keyResult`}
                      value={keyResult.keyResult}
                      onChange={(e) => handleKeyResultChange(e, index)}
                      placeHolder="Enter key results"
                    />

                    {keyResult.isEditable && userType !== "individual" && (
                      <LabelComponent title="Select Owner" className={`mb-4`}>
                        <FieldMultiSelect
                          value={keyResult.owners}
                          selectOptions={ownersList}
                          name={`keyResults[${index}].owners`}
                          onSelect={(data) => onSelect(data, index)}
                        />
                      </LabelComponent>
                    )}

                    {keyResult.showCustomResults &&
                    keyResult.owners.length >= 2 ? (
                      <>
                        <hr className="hr-class-1" />
                        <Form.Label className="label-class d-flex">
                          Custom Key Result
                          {!keyResult.isExpanded && (
                            <span className="ms-3 d-flex">
                              {keyResult.customKeyResult.map(
                                (customKey, indx) => (
                                  <>
                                    {customKey.ownerImage ? (
                                      <img
                                        className="n-margin-profile"
                                        src={customKey.ownerImage}
                                        alt="owner-iamge"
                                      />
                                    ) : (
                                      <Avatar
                                        sx={{
                                          width: "25px",
                                          height: "25px",
                                          fontSize: "15px",
                                          backgroundColor: colorArray[indx],
                                        }}
                                      >
                                        {customKey.owner[0]}
                                      </Avatar>
                                    )}
                                  </>
                                )
                              )}
                            </span>
                          )}
                        </Form.Label>
                        {keyResult.isExpanded && (
                          <>
                            <hr className="hr-class-2" />
                            {keyResult.customKeyResult.map((customKey, i) => (
                              <div
                                className="d-flex jsutify-content-between "
                                style={{
                                  justifyContent: "space-between",
                                }}
                                key={i}
                              >
                                <FieldInput
                                  type="text"
                                  placeHolder="owner"
                                  label={i === 0 ? "OWNER" : null}
                                  name={`keyResults[${index}].customKeyResult[${i}].owner`}
                                  value={customKey.owner}
                                  onChange={handleCustomKeyResultChange}
                                  style={{
                                    maxWidth: "130px",
                                  }}
                                  className="custom-key-margin"
                                />

                                <FieldToolTipInput
                                  type="text"
                                  placeHolder="description"
                                  label={i === 0 ? "DESCRIPTION" : null}
                                  name={`keyResults[${index}].customKeyResult[${i}].desc`}
                                  value={customKey.desc}
                                  onChange={handleCustomKeyResultChange}
                                  style={{
                                    width: "auto",
                                  }}
                                  className="custom-key-margin"
                                />

                                <FieldInput
                                  type="number"
                                  placeHolder="start value"
                                  name={`keyResults[${index}].customKeyResult[${i}].startValue`}
                                  value={customKey.startValue}
                                  onChange={(e) =>
                                    handleStartValueChange(e, index, i)
                                  }
                                  label={i === 0 ? "START VALUE" : null}
                                  style={{ maxWidth: "80px" }}
                                  className="custom-key-margin"
                                />
                                <FieldInput
                                  type="number"
                                  placeHolder="end value"
                                  name={`keyResults[${index}].customKeyResult[${i}].endValue`}
                                  value={customKey.endValue}
                                  onChange={(e) =>
                                    handleEndValueChange(e, index, i)
                                  }
                                  label={i === 0 ? "END VALUE" : null}
                                  style={{ maxWidth: "80px" }}
                                  className="custom-key-margin"
                                />

                                <FieldDropDown
                                  name={`keyResults[${index}].customKeyResult[${i}].quantification`}
                                  value={customKey.quantification}
                                  onChange={(e) =>
                                    handleQuantificationChange(e, index, i)
                                  }
                                  optionsList={[
                                    { value: "Number", label: "Number" },
                                    {
                                      value: "Percentage",
                                      label: "Percentage",
                                    },
                                  ]}
                                  style={{ width: "100px", margin: "0px" }}
                                  className="custom-key-margin"
                                  label={i === 0 ? "QUANTIFICATION" : null}
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {keyResult.owners.length < 2 && (
                          <LabelComponent title="Select Quantification">
                            <div className="d-flex class-dropdown">
                              <div className="objective-dropdown">
                                <FieldDropDown
                                  optionsList={[
                                    { value: "Number", label: "Number" },
                                    {
                                      value: "Percentage",
                                      label: "Percentage",
                                    },
                                  ]}
                                  value={
                                    keyResult.customKeyResult[0].quantification
                                  }
                                  // name={`keyResults[${index}].quantification`}
                                  name={`keyResults[${index}].customKeyResult[0].quantification`}
                                  onChange={(e) =>
                                    handleQuantificationChange(e, index, 0)
                                  }
                                />
                              </div>
                              <div className="date-dropdown d-flex key-result-value">
                                <div className={"input-margin"}>
                                  <FieldInput
                                    type="number"
                                    placeHolder="Start value"
                                    name={`keyResults[${index}].customKeyResult[0].startValue`}
                                    onChange={(e) =>
                                      handleStartValueChange(e, index, 0)
                                    }
                                    value={
                                      keyResult.customKeyResult[0].startValue
                                    }
                                    label="START VALUE"
                                    style={{ width: "100px" }}
                                  />
                                </div>
                                <div>
                                  <FieldInput
                                    type="number"
                                    placeHolder="End value"
                                    value={
                                      keyResult.customKeyResult[0].endValue
                                    }
                                    name={`keyResults[${index}].customKeyResult[0].endValue`}
                                    onChange={(e) =>
                                      handleEndValueChange(e, index, 0)
                                    }
                                    label="END VALUE"
                                    style={{ width: "100px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </LabelComponent>
                        )}
                        <ButtonComponent
                          className="add-key-btn p-3 mt-3"
                          disabled={keyResult.owners.length < 2}
                          onClick={() => handleAddCustomResults(index)}
                        >
                          + ADD CUSTOM KEY RESULTS
                        </ButtonComponent>
                      </>
                    )}
                  </div>
                </div>
              ))}

            <ButtonComponent
              disabled={
                values.keyResults[values.keyResults.length - 1].keyResult &&
                values.keyResults[values.keyResults.length - 1].owners.length >
                  0 &&
                values.keyResults[values.keyResults.length - 1]
                  .customKeyResult[0].startValue &&
                values.keyResults[values.keyResults.length - 1]
                  .customKeyResult[0].endValue
                  ? false
                  : true
              }
              className="w-100 add-key-btn border-btn p-3 mt-4"
              onClick={() => handleAddKeyResult(arrayHelpers)}
            >
              + ADD ANOTHER KEY RESULTS
            </ButtonComponent>
          </>
        )}
      />
    </>
  );
}
