import customAxios from "../helpers/apiConfig";
import { getToken } from "../helpers/Utils";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getHeader() {
    const token = getToken();
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
      };
    } else return null;
  },
  async PostService(path, body, params) {
    const headers = this.getHeader();

    return await customAxios
      .post(path, body, { headers, params })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },

  async getService(path, params) {
    const headers = this.getHeader();

    if (params?.byId) path = `${path}/${params.id}`;

    return await customAxios
      .get(path, { headers, params })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },

  async deleteService(path, params) {
    const headers = this.getHeader();

    if (params?.byId) path = `${path}/${params.id}`;

    return await customAxios
      .delete(path, { headers })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },

  async PutService(path, body, params) {
    const headers = this.getHeader();
    if (params?.byId) path = `${path}/${params.id}`;
    return await customAxios
      .put(path, body, { headers, params })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
};
