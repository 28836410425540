import {createAsyncThunk} from "@reduxjs/toolkit";
import API from "../../../api/ApiService";
import APIConstants from "../../../shared/constants/APIConstants";

import {fetch_error, fetch_start, fetch_success,} from "../../reducers/CommonReducer";

const {
    getCapability,
} = APIConstants


export const toCreateCapability = createAsyncThunk(
    "CreateCapability",
    async (body, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.PostService(getCapability, body);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                // thunkAPI.dispatch(show_message(res.data.message));
            } else {
                console.log(res.data.message)
                // thunkAPI.dispatch(fetch_error(res.data.message));
            }
            return res.data;
        } catch (error) {
            console.log(error)
            // thunkAPI.dispatch(fetch_error(error.message));
            return {success: false, message: error.message};
        }
    }
);

export const getAllCapabilities = createAsyncThunk(
    "allCapabilities",
    async (params, thunkAPI) => {
        thunkAPI.dispatch(fetch_start());
        const res = await API.getService(`${getCapability}`);
        if (res.data.success) {
            thunkAPI.dispatch(fetch_success());
        } else {
            thunkAPI.dispatch(fetch_error(res.data.message));
        }
        return res.data;
    }
);

export const getAllCapabilitiesNames = createAsyncThunk(
    "getAllCapabilitiesNames",
    async (params, thunkAPI) => {
        thunkAPI.dispatch(fetch_start());
        const res = await API.getService(`${getCapability}/name`);
        if (res.data.success) {
            thunkAPI.dispatch(fetch_success());
        } else {
            thunkAPI.dispatch(fetch_error(res.data.message));
        }
        return res.data;
    }
);

export const getSingleCapability = createAsyncThunk(
    "getSingleCapability",
    async (params, thunkAPI) => {
        thunkAPI.dispatch(fetch_start());
        const res = await API.getService(`${getCapability}/${params}`);
        if (res.data.success) {
            thunkAPI.dispatch(fetch_success());
        } else {
            thunkAPI.dispatch(fetch_error(res.data.message));
        }
        return res.data;
    }
);

export const toSaveCapabilitySheet = createAsyncThunk(
    "toSaveCapabilitySheet",
    async (body, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.PutService(`/api/capability/${body.id}`, body.data);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                // thunkAPI.dispatch(show_message(res.data.message));
            } else {
                thunkAPI.dispatch(fetch_error(res.data.message));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error.message));
            return {success: false, message: error.message};
        }
    }
)