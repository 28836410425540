/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import InputField from "../../../../../shared/ui/InputField";
import DropDown from "../../../../../shared/ui/DropDown";
import CustomModal from "../../../../../shared/ui/Modal";
import { toCreateDesignation, toGetDepartment } from "../../../../../redux/actions";


const CreateDesignation = ({ showModal, setShowModal, usedFor, handleOnSave }) => {
    const dispatch = useDispatch();
    const [departmentList, setDepartmentList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(async () => {
        const res = await dispatch(toGetDepartment());
        if (res.success) {
            const department = res.data.map((e) => ({ label: e.name, value: e.department_id }));
            setDepartmentList(department);
            setIsLoading(false);
            handleOnSave();
        }
    }, [showModal]);


    const validationSchema = yup.object({
        designation_name: yup.string().required("Designation name is required"),
        department_id: yup.string().required("Department is required")
    });

    const handleSubmit = async (values, { resetForm }) => {
        const res = await dispatch(toCreateDesignation(values));
        if (res.success) {
            resetForm();
            setShowModal(false);
        }
    }

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{
                designation_name: '',
                department_id: '',
            }}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                setFieldValue,
                touched,
                handleChange,
                handleSubmit,
                handleReset,
            }) => {
                return (
                    <>
                        <CustomModal
                            show={showModal}
                            setShow={setShowModal}
                            title={`Create Designation`}
                            btnText2={"CREATE"}
                            btnText1={"DISCARD"}
                            size="lg"
                            usedFor={usedFor}
                            on_click_Done={handleSubmit}
                            on_click_button_one={() => {
                                handleReset();
                                setShowModal(false);
                            }}
                        >
                            <Row className="mb-3">
                                <Form.Label className={"select-label"}>Designation Name</Form.Label>

                                <InputField
                                    type="text"
                                    name={"designation_name"}
                                    value={values.designation_name}
                                    onChange={handleChange}
                                    placeHolder=""
                                    errors={
                                        errors.designation_name && touched.designation_name && errors.designation_name
                                    }
                                />

                            </Row>
                            {!isLoading && (
                                <Row className="mb-3">
                                    <DropDown
                                        optionsList={departmentList}
                                        label={"Department"}
                                        value={values.department_id}
                                        name={"department_id"}
                                        onChange={handleChange}
                                        errors={
                                            errors.department_id &&
                                            touched.department_id &&
                                            errors.department_id
                                        }
                                    />
                                </Row>
                            )}
                        </CustomModal>
                    </>
                );
            }}
        </Formik>
    )
}

export default CreateDesignation;