import { createSlice } from "@reduxjs/toolkit";
import { getAllGoalsList } from "../../actions/Goals";

const GoalsReducer = createSlice({
  name: "Goals",
  initialState: {
    goalsList: [],
  },
  reducers: {},
  extraReducers: {
    [getAllGoalsList.fulfilled]: (state, action) => {
      state.goalsList = action.payload.data;
    },
  },
});

export default GoalsReducer.reducer;
