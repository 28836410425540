import { Tooltip } from "@mui/material";

export default function CustomToolTip({ children, value }) {
  if (value && value.length > 20) {
    return (
      <Tooltip title={value} placement="top" arrow>
        {children}
      </Tooltip>
    );
  } else return <>{children}</>;
}
