import React, {useEffect, useState} from "react";
import {Nav} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "./sidebar.scss";

import AddObjective from "../../modules/Dashboard/OKR/CreateObjective";
import ButtonComponent from "../../shared/ui/Button";
import SidebarAccordion from "../../shared/ui/sidebarAccordion";
import SidebarBox from "../../assets/images/Side_bar/sidebar-box.png";
import AddGoal from "../../modules/Dashboard/Goals/AddGoal";
import {toGetAllQuarter, toGetAllUsersList} from "../../redux/actions";
import CreateQuarter from "../../modules/Dashboard/QPBVP/Quarter/Create";
import {Plus} from "react-iconly";
import CreateCapabilityModal from "../../modules/Dashboard/QPBVP/Capability/Create";
import CreateUser from "../../modules/Dashboard/QPBVP/User/Create";
import CreateDesignation from "../../modules/Dashboard/QPBVP/Designation/Create";
import CreateDepartment from "../../modules/Dashboard/QPBVP/Department/Create";
import {getUserData} from "../../helpers/Utils";

const Sidebar = ({routes, btnTitle, usedFor}) => {
    const {
        userPermissions: {userType, hasQpbvp},
    } = useSelector(({AuthUserReducer}) => AuthUserReducer);
    const {quarterList} = useSelector(
        ({CommonReducer}) => CommonReducer
    );
    const dispatch = useDispatch();
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const [showAddOKRModal, setShowAddOKRModal] = useState(false);
    const [showAddGoalModal, setShowAddGoalModal] = useState(false);
    const [showAddQuarterModal, setShowAddQuarterModal] = useState(false);
    const [showAddCapabilityModal, setShowAddCapabilityModal] = useState(false);
    const [showAddDepartmentModal, setShowAddDepartment] = useState(false);
    const [showAddDesignationModal, setShowAddDesignationModal] = useState(false);
    // const [showAddDesignationModal, setShowAddDesignationModal] = useState(false);

    const [adminWithQPBVP, setAdminWithQPBVP] = useState(false);
    // user Table
    const [showUserModal, setShowUserModal] = useState(false);

    const [userData, setUseData] = useState({});

    const handleRoute = (path, name) => {
        if (usedFor === "goals") navigate(path, {state: name});
        if (usedFor === 'quarter') navigate(path);
        else {
            let ids = path.split("/");
            const id = ids[ids.length - 1];
            navigate(path, {state: `${name?.first_name || name?.name} ${id}`});
        }
    };

    const checkSidebarType = (route, type) => {
        if (route.sidebars && Array.isArray(route.sidebars)) {
            return route.sidebars.some((sidebar) => sidebar.type === type);
        }
        return false;
    };
    const getSidebarLink = (route, type) => {
        const sidebar = route.sidebars.find(sidebar => sidebar.type === type);
        return sidebar.link;
    };

    const handleSaveButton = () => {
        console.log('clicked modal')
    }


    useEffect(() => {
        const user = getUserData();
        setUseData(user)
    }, [])

    useEffect(() => {
        if (userType === 'super_admin' && hasQpbvp && currentLocation.pathname.includes("my-qpbvp")) {
            setAdminWithQPBVP(true)
        } else {
            setAdminWithQPBVP(false)
        }
        // console.log(quarterList)
    }, [currentLocation])

    const handleAddClick = () => {
        // console.log()
        switch (usedFor) {
            case "goals":
                setShowAddGoalModal(true);
                break;
            case "quarter":
                setShowAddQuarterModal(true);
                break;
            case "capability":
                setShowAddCapabilityModal(true);
                break;
            case "user":
                setShowUserModal(true);
                break;
            case "department":
                setShowAddDepartment(true);
                break;
            case "designation":
                setShowAddDesignationModal(true);
                break;
            default:
                setShowAddOKRModal(true);
        }
    };

    useEffect(async () => {
        await dispatch(toGetAllUsersList());
        await dispatch(toGetAllQuarter());
        // await dispatch(toGetAllAsyncDeptList());
    }, []);

    const renderMenu = (route, index) => {
        if (route.type === "item" || route.type === 'section') {
            return (
                <React.Fragment key={index}>
                    {checkSidebarType(route, 'quarters') &&
                        <SidebarAccordion
                            title={"QUARTERS"}
                            itemList={quarterList}
                            handleClick={handleRoute}
                            path={getSidebarLink(route, 'quarters')}
                            key={index}
                        />
                    }
                    {
                        route.type === 'item' &&

                        <div
                            key={index}
                            className={`item-class ${currentLocation.pathname.includes(route.path) ? "active" : ""
                            }`}
                            onClick={() => handleRoute(route.path)}
                        >
                            {route.name}
                        </div>

                    }
                </React.Fragment>

            );
        }
        return null;
    }

    return (
        <>
            <Nav className="sidebar" activeKey="/home">
                {!adminWithQPBVP &&
                    <div>
                        {btnTitle &&
                            <ButtonComponent
                                className="add-btn"
                                onClick={handleAddClick}
                            >
                                {/*<img src={PlusIcon} alt="Icon" />{" "}*/}
                                <Plus set="light" primaryColor="white" size={'medium'}/>
                                <span>{btnTitle}</span>
                            </ButtonComponent>
                        }

                        {/* userPermissions.userType */}
                        {routes.filter((i) => i.access.includes(userType)).map((route, index) => renderMenu(route, index))}
                    </div>
                }
                {adminWithQPBVP &&
                    <div>
                        {routes.filter((i) => i.access.includes('user')).map((route, index) => renderMenu(route, index))}
                    </div>
                }
                <div className="mx-auto mt-5">
                    <img alt="sidebar-box" src={SidebarBox}/>
                </div>
            </Nav>
            <AddObjective
                showModal={showAddOKRModal}
                setShowModal={setShowAddOKRModal}
                usedFor={usedFor}
            />

            <AddGoal
                showModal={showAddGoalModal}
                setShowModal={setShowAddGoalModal}
                usedFor={usedFor}
            />
            <CreateQuarter showModal={showAddQuarterModal} setShowModal={setShowAddQuarterModal}/>
            <CreateCapabilityModal showModal={showAddCapabilityModal} setShowModal={setShowAddCapabilityModal}/>
            <CreateUser showModal={showUserModal} setShowModal={setShowUserModal}/>
            <CreateDepartment showModal={showAddDepartmentModal} setShowModal={setShowAddDepartment}/>
            <CreateDesignation showModal={showAddDesignationModal} setShowModal={setShowAddDesignationModal}
                               handleOnSave={() => handleSaveButton}/>
        </>
    );
};
export default Sidebar;
