/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { useLocation } from "react-router";

import InputField from "../../../../../shared/ui/InputField";
import DropDown from "../../../../../shared/ui/DropDown";
import CustomModal from "../../../../../shared/ui/Modal";
import { toCreateDepartment } from "../../../../../redux/actions";
import { useNavigate } from "react-router-dom";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';



const CreateDepartment = ({ showModal, setShowModal, usedFor, handleOnSave }) => {
    const dispatch = useDispatch();

    const validationSchema = yup.object({
        department_name: yup.string().required("Department name is required"),
    });

    const handleSubmit = async (values, { resetForm }) => {
        var body = {
            "name": values.department_name,
            "organization_id": 1,
        }

        const res = await dispatch(toCreateDepartment(body));
        if (res.success) {
            resetForm();
            setShowModal(false);
            handleOnSave();
        }
    }

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{
                department_name: '',
              
            }}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                setFieldValue,
                touched,
                handleChange,
                handleSubmit,
                handleReset,
            }) => {
                return (
                    <>
                        <CustomModal
                            show={showModal}
                            setShow={setShowModal}
                            title={`Create Department`}
                            btnText2={"CREATE"}
                            btnText1={"DISCARD"}
                            size="lg"
                            usedFor={usedFor}
                            on_click_Done={handleSubmit}
                            on_click_button_one={() => {
                                handleReset();
                                setShowModal(false);
                            }}
                        >
                            <Form.Label className={"select-label"}>Department Name</Form.Label>
                            <InputField
                                type="text"
                                name={"department_name"}
                                value={values.department_name}
                                onChange={handleChange}
                                placeHolder=""
                                errors={
                                    errors.department_name && touched.department_name && errors.department_name
                                }
                            />
                        </CustomModal>
                    </>
                );
            }}
        </Formik>
    )
}

export default CreateDepartment;