import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import CustomizedSnackbars from "../Snackbar";

const InfoView = ({ hideError, hideLoader }) => {
  const { error, loading, message } = useSelector(
    ({ CommonReducer }) => CommonReducer
  );
  const showMessage = () => {
    return <CustomizedSnackbars success={true} message={message.toString()} />;
  };

  const showError = () => {
    return <CustomizedSnackbars success={false} message={error.toString()} />;
  };

  return (
    <>
      {message && showMessage()}
      {!hideError && error && showError()}
    </>
  );
};

InfoView.defaultProps = {
  hideError: false,
  hideLoader: false,
};

InfoView.propTypes = {
  hideError: PropTypes.bool,
  hideLoader: PropTypes.bool,
};

export default InfoView;
