import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Slide } from "@mui/material";
import FailIcon from "../../../assets/images/OKRs/toaster-fail-icon.png";
import SuccessIcon from "../../../assets/images/OKRs/toaster-success-icon.png";
import CloseIcon from "../../../assets/images/OKRs/toaster-close-icon.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ({ message, success }) {
  const [open, setOpen] = React.useState(true);
  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      TransitionComponent={TransitionLeft}
      sx={{
        borderRadius: "0px",
        "& .MuiPaper-root": {
          borderRadius: "0px",
          boxShadow: "none",
          border: "#E8E9ED 1px solid",
          backgroundColor: "white",
          color: "black",
        },
      }}
      autoHideDuration={2000}
    >
      <Alert
        onClose={handleClose}
        action={false}
        icon={
          success ? (
            <img
              src={SuccessIcon}
              height={30}
              width={30}
              alt={"success-icon"}
            />
          ) : (
            <img src={FailIcon} height={30} width={30} alt={"fail-icon"} />
          )
        }
        sx={{
          width: "100%",
          borderRadius: "0px",
          // "& .MuiAlert-action": {
          //   position: "relative",
          //   bottom: "17px",
          //   right: "275px",
          //   padding: 0,
          //   margin: 0,
          // },
          "& .MuiAlert-message": {
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        <img
          src={CloseIcon}
          alt="close-icon"
          height={20}
          width={20}
          onClick={handleClose}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "-10px",
            left: "13px",
          }}
        />
        <b>{message} </b>
      </Alert>
    </Snackbar>
  );
}
