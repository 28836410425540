import { Login } from "../../actions/auth";
import { createSlice } from "@reduxjs/toolkit";
import { setUserPermissions } from "../../../helpers/Utils";

const AuthUserReducer = createSlice({
  name: "AuthUser",
  initialState: {
    userPermissions: {},
    user: null,
    isLogin: false,
  },
  reducers: {
    setUserPermissionsReducer: (state, action) => {
      const payload = setUserPermissions(action.payload);
      state.userPermissions = payload;
    },
    updateAuthUser: (state, action) => {
      state.user = action.payload.data;
      state.isLogin = action.payload.isLogin;
    },
  },
  extraReducers: {
    [Login.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.user = action.payload.data;
        state.isLogin = true;
      }
    },
  },
});

export const { setUserPermissionsReducer, updateAuthUser } =
  AuthUserReducer.actions;

export default AuthUserReducer.reducer;
