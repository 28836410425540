import { createSlice } from "@reduxjs/toolkit";
import {
  toGetAllUsersList,
  toGetAllStatusList,
  toGetAllAsyncDeptList,
    toGetAllQuarter
} from "../../actions/CommonActions";

const CommonReducer = createSlice({
  name: "commonActions",
  initialState: {
    error: "",
    loading: "",
    message: "",
    usersList: [],
    statusList: [],
    deptList: [],
    quarterList: [],
    objectivesCycleId: null,
    objectivesStatusId: null,
    goalsStatusId: null,
  },
  reducers: {
    fetch_start: (state, action) => {
      state.loading = true;
      state.error = "";
      state.message = "";
    },
    fetch_success: (state, action) => {
      state.loading = false;
      state.error = "";
      state.message = "";
    },
    fetch_error: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.message = "";
    },
    show_message: (state, action) => {
      state.loading = false;
      state.message = action.payload;
      state.error = "";
    },
    objectiveFilterData: (state, action) => {
      state.objectivesCycleId = action.payload.cycleData || null;
      state.objectivesStatusId = action.payload.statusData || null;
      state.goalsStatusId = action.payload.goalsStatusData || null;
    },
  },
  extraReducers: {
    [toGetAllUsersList.fulfilled]: (state, action) => {
      state.usersList = action.payload.data;
    },
    [toGetAllStatusList.fulfilled]: (state, action) => {
      state.statusList = action.payload.data;
    },
    [toGetAllAsyncDeptList.fulfilled]: (state, action) => {
      state.deptList = action.payload.data;
    },
    [toGetAllQuarter.fulfilled]: (state, action) => {
      state.quarterList = action.payload.data;
    },
  },
});

export const {
  fetch_error,
  fetch_start,
  fetch_success,
  show_message,
  objectiveFilterData,
} = CommonReducer.actions;

export default CommonReducer.reducer;
