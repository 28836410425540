import React, {Fragment, useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import Sidebar from "../../components/AppSidebar";
import HeaderComponent from "../../components/Header";
import {QPBVPRoutes} from "../../routes/DashboardRoutes";
import "./index.css";
import {useSelector} from "react-redux";

const sidebarConfig = [
    {
        path: "qpbvp/quarter",
        btnTitle: "Create New Quarter",
        usedFor: "quarter",
    },
    {
        path: "qpbvp/capabilities",
        btnTitle: "Create New Capability",
        usedFor: "capability",
    },
    {
        path: "qpbvp/users",
        btnTitle: "Create New User",
        usedFor: "user",
    },
    {
        path: "qpbvp/department",
        btnTitle: "Department",
        usedFor: "department",
    },
    {
        path: "qpbvp/designation",
        btnTitle: "Designation",
        usedFor: "designation",
    },
    {
        path: "my-qpbvp",
        btnTitle: "",
        usedFor: "my-qpbvp",
    },
    {
        path: "my-resources",
        btnTitle: "",
        usedFor: "my-resources",
    },
    {
        path: "summary-view",
        btnTitle: "Summary View",
        usedFor: "summary",
    },
];


export default function DashboardLayout() {

    const [adminWithQBPVP, setAdminWithQPBVP] = useState(false);
    const {
        userPermissions: {userType, hasQpbvp},
    } = useSelector(({AuthUserReducer}) => AuthUserReducer);

    const currentLocation = useLocation();
    const sidebar = sidebarConfig.find((config) =>
        currentLocation.pathname.includes(config.path)
    );

    useEffect(() => {
        if (userType === 'super_admin' && hasQpbvp && currentLocation.pathname.includes("my-qpbvp")) {
            setAdminWithQPBVP(true)
        } else {
            setAdminWithQPBVP(false)
        }
        // console.log(quarterList)
    }, [currentLocation])

    const Loading = () => <div className="text-align-center">Loading...</div>;
    const AllRoutes = (routes, userType) => {
        return routes.filter((i) => i.access.includes(userType)).map((route, i) => {
            return (
                <Fragment key={i}>
                    <Route
                        exact={route.exact}
                        name={route.name}
                        path={route.path}
                        element={route.component}
                        key={`main-route-${i}`}
                    />
                    {route.children.map((child, i) => (
                        <Route
                            exact={child.exact}
                            name={child.name}
                            path={child.path}
                            element={child.component}
                            key={`child-route-${i}`}
                        />
                    ))}
                </Fragment>
            );

            return null;
        });
    };

    return (
        <>
            <div className="layout">
                <HeaderComponent/>
                {/* Header and sidebar will come  here */}
                <div className="d-layout">
                    <div className="sidebar-class">
                        {sidebar && (
                            <Sidebar
                                routes={QPBVPRoutes}
                                btnTitlebtnTitle={sidebar.btnTitle}
                                usedFor={sidebar.usedFor}
                            />
                        )}
                    </div>

                    <div className="page-content">
                        <React.Suspense fallback={<Loading/>}>
                            <Routes>
                                {AllRoutes(QPBVPRoutes, userType)}
                                {adminWithQBPVP && AllRoutes(QPBVPRoutes, 'user')}
                            </Routes>
                        </React.Suspense>
                    </div>
                </div>
            </div>
        </>
    );
}
