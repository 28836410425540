export const SUPER_ADMIN_PERMISSIONS = {
    userType: "super_admin",
    hasQpbvp: false,
    CrudUsers: true,
    CrudDepartments: true,

    OrganizationCrudPermissions: true,
    DepartmentCrudPermissions: true,
    IndividualCrudPermissions: true,

    GrowthGoalCrudPermissions: true,
};

export const REVIEWER_PERMISSION = {
    userType: "reviewer",
    hasQpbvp: true,
    CrudUsers: false,
    CrudDepartments: false,

    OrganizationCrudPermissions: false,
    DepartmentCrudPermissions: true,
    IndividualCrudPermissions: true,

    GrowthGoalCrudPermissions: true,
};

export const USER_PERMISSIONS = {
    userType: "user",
    hasQpbvp: true,
    CrudUsers: false,
    CrudDepartments: false,

    OrganizationCrudPermissions: false,
    DepartmentCrudPermissions: false,
    IndividualCrudPermissions: true,

    GrowthGoalCrudPermissions: true,
};
