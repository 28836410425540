import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toGetAllCycleData } from "../../../../redux/actions";
import { getAllGoalsList, toCreateGoal } from "../../../../redux/actions/Goals";
import DropDown from "../../../../shared/ui/DropDown";
import CustomModal from "../../../../shared/ui/Modal";
import "./add-goal.css";
export default function AddGoal({ showModal, setShowModal }) {
  const { user } = useSelector(({ AuthUserReducer }) => AuthUserReducer);
  const { objectivesCycleId, goalsStatusId } = useSelector(
    ({ CommonReducer }) => CommonReducer
  );

  const { pathname } = useLocation();
  const [goalValue, setGoalValue] = useState("");
  const [cycleValue, setCycleValue] = useState("");
  const [objCycleList, setObjCycleList] = useState([]);
  const [userId, setUserId] = useState("");

  const dispatch = useDispatch();
  const handleAddGoal = async () => {
    const body = {
      goal: goalValue,
      objectives_cycle_id: cycleValue,
      user_id: userId || user.user_id,
    };
    const res = await dispatch(toCreateGoal(body));
    if (res.payload.success) {
      setShowModal(false);
      setGoalValue("");
      setCycleValue("");
      setTimeout(async () => {
        let filterData = {};
        if (goalsStatusId?.label !== "All") {
          filterData.objectives_cycle_id = objectivesCycleId?.value;
          filterData.achievement_status = goalsStatusId?.label;
        } else {
          filterData.objectives_cycle_id = objectivesCycleId?.value;
        }

        await dispatch(
          getAllGoalsList({
            user_id: userId || user.user_id,
            ...filterData,
          })
        );
      }, [500]);
    }
  };

  useEffect(async () => {
    const objCycleRes = await dispatch(toGetAllCycleData());
    if (objCycleRes.success) {
      setObjCycleList(objCycleRes.data);
    }
  }, []);

  useEffect(() => {
    const id = pathname.split("/");
    setUserId("");
    if (!isNaN(id[id.length - 1])) {
      setUserId(Number(id[id.length - 1]));
    }
  }, [pathname]);

  return (
    <CustomModal
      show={showModal}
      setShow={setShowModal}
      title={`Add Goal`}
      btnText2={"Done"}
      size="md"
      centered
      closeButton
      on_click_button_one={() => {
        setGoalValue("");
        setShowModal(false);
      }}
      on_click_Done={handleAddGoal}
    >
      <div className="add-goal-title d-flex">
        <span className="d-flex align-items-center me-2">Active cycle :</span>
        <DropDown
          optionsList={objCycleList}
          value={cycleValue}
          onChange={(e) => setCycleValue(e.target.value)}
          style={{ width: "150px", height: "40px" }}
        />
      </div>
      <textarea
        value={goalValue}
        onChange={(e) => setGoalValue(e.target.value)}
        className="text-area-goal w-100 mt-4"
        rows={3}
        placeholder="Enter a Goal..."
      ></textarea>
    </CustomModal>
  );
}
