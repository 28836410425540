import {createAsyncThunk} from "@reduxjs/toolkit";
import API from "../../../api/ApiService";
import APIConstants from "../../../shared/constants/APIConstants";

import {fetch_error, fetch_start, fetch_success, show_message,} from "../../reducers/CommonReducer";

const {
    getQuarter,
    getQuarterSheet,
    getQuarterEmployee
} = APIConstants

export const toGetQuarter = createAsyncThunk(
    "QuarterAll",
    async (params, thunkAPI) => {
        thunkAPI.dispatch(fetch_start());
        const res = await API.getService(getQuarter, {...params});
        if (res.data.success) {
            thunkAPI.dispatch(fetch_success());
        } else {
            thunkAPI.dispatch(fetch_error(res.data.message));
        }
        return res.data;
    }
);

export const getSingleQuarter = createAsyncThunk(
    "QuarterSingle",
    async (params, thunkAPI) => {
        thunkAPI.dispatch(fetch_start());
        const res = await API.getService(`${getQuarter}/${params}`);
        if (res.data.success) {
            thunkAPI.dispatch(fetch_success());
        } else {
            thunkAPI.dispatch(fetch_error(res.data.message));
        }
        return res.data;
    }
);
export const getSingleQuarterSheet = createAsyncThunk(
    "QuarterSheet",
    async (params, thunkAPI) => {
        thunkAPI.dispatch(fetch_start());
        const res = await API.getService(`/api/quarter_sheet/quarter/${params}`);
        if (res.data.success) {
            thunkAPI.dispatch(fetch_success());
        } else {
            thunkAPI.dispatch(fetch_error(res.data.message));
        }
        return res.data;
    }
);

export const getAllQuarterSheetEmployee = createAsyncThunk(
    "QuarterSheet",
    async (params, thunkAPI) => {
        thunkAPI.dispatch(fetch_start());
        const res = await API.getService(`/api/quarter_employee/quarter/${params}`);
        if (res.data.success) {
            thunkAPI.dispatch(fetch_success());
        } else {
            thunkAPI.dispatch(fetch_error(res.data.message));
        }
        return res.data;
    }
);

export const toCreateQuarter = createAsyncThunk(
    "createQuarter",
    async (body, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.PostService(getQuarter, body);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                thunkAPI.dispatch(show_message(res.data.message));
            } else {
                thunkAPI.dispatch(fetch_error(res.data.message));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error.message));
            return {success: false, message: error.message};
        }
    }
);

export const toUpdateQuarter = createAsyncThunk(
    "createQuarter",
    async (body, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.PutService(`/api/quarter/${body.quarter_id}`, body);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                thunkAPI.dispatch(show_message(res.data.message));
            } else {
                thunkAPI.dispatch(fetch_error(res.data.message));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error.message));
            return {success: false, message: error.message};
        }
    }
);

export const toSaveQuarterSheet = createAsyncThunk(
    "SaveSheet",
    async (body, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.PutService(`/api/quarter_employee/quarter/${body.id}`, body.data);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                // thunkAPI.dispatch(show_message(res.data.message));
            } else {
                thunkAPI.dispatch(fetch_error(res.data.message));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error.message));
            return {success: false, message: error.message};
        }
    }
)

// publish
export const toPublishOne = createAsyncThunk(
    "toPublishOne",
    async (body, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.PostService(`/api/quarter_employee/${body.id}/publish`);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                // thunkAPI.dispatch(show_message(res.data.message));
            } else {
                thunkAPI.dispatch(fetch_error(res.data.message));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error.message));
            return {success: false, message: error.message};
        }
    }
);
// unpublish
export const toUnpublishOne = createAsyncThunk(
    "toPublishOne",
    async (body, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.PostService(`/api/quarter_employee/${body.id}/unpublish`);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                // thunkAPI.dispatch(show_message(res.data.message));
            } else {
                thunkAPI.dispatch(fetch_error(res.data.message));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error.message));
            return {success: false, message: error.message};
        }
    }
);
// publish all
export const toPublishAll = createAsyncThunk(
    "toPublishAll",
    async (body, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.PostService(`/api/quarter_employee/quarter/${body}/publish`);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                // thunkAPI.dispatch(show_message(res.data.message));
            } else {
                thunkAPI.dispatch(fetch_error(res.data.message));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error.message));
            return {success: false, message: error.message};
        }
    }
);
// unpublish all
export const toCloseQuarter = createAsyncThunk(
    "toCloseQuarter",
    async (body, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.PutService(`/api/quarter/${body}/close`);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                // thunkAPI.dispatch(show_message(res.data.message));
            } else {
                thunkAPI.dispatch(fetch_error(res.data.message));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error.message));
            return {success: false, message: error.message};
        }
    }
);
export const toQuarterSummary = createAsyncThunk(
    "QuarterSummary",
    async (year, thunkAPI) => {
        thunkAPI.dispatch(fetch_start());
        // const res = await API.getService(getQuarter, {...params});
        const res = await API.getService(`/api/quarter/summary/${year}`);
        if (res.data.success) {
            thunkAPI.dispatch(fetch_success());
        } else {
            thunkAPI.dispatch(fetch_error(res.data.message));
        }
        return res.data;
    }
);

// lock unlock
export const toLockUnlockEmployeeQuarter = createAsyncThunk(
    "toLockUnlockEmployeeQuarter",
    async (data, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.PutService(`/api/quarter_employee/lock_unlock`, data);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                // thunkAPI.dispatch(show_message(res.data.message));
            } else {
                thunkAPI.dispatch(fetch_error(res.data.message));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error.message));
            return {success: false, message: error.message};
        }
    }
);

export const forPublishHistory = createAsyncThunk(
    "forPublishHistory",
    async (params, thunkAPI) => {
        thunkAPI.dispatch(fetch_start());
        const res = await API.getService(`/api/quarter_employee/${params}/status`);
        if (res.data.success) {
            thunkAPI.dispatch(fetch_success());
        } else {
            thunkAPI.dispatch(fetch_error(res.data.message));
        }
        return res.data;
    }
)