import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../api/ApiService";
import APIConstants from "../../../shared/constants/APIConstants";


import {
    fetch_start,
    fetch_success,
    fetch_error,
    show_message,
} from "../../reducers/CommonReducer";

const {
    getDesignation,
} = APIConstants

export const toGetDesignation = () => {
    return async () => {
        try {
            const res = await API.getService(getDesignation);
            if (res.data.success) {
                return { success: true, data: res.data.data };
            } else {
                return res.data;
            }
        } catch (error) {
            return { success: false };
        }
    }
}

export const toCreateDesignation = (body) => {
    return async () => {
        try {
            console.log(body);
            const res = await API.PostService(getDesignation, body);
            if (res.data.success) {
                return { success: true, data: res.data.data };
            } else {
                return res.data;
            }
        } catch (error) {
            return { success: false };
        }
    }
}

export const toUpdateDesignation = (id, body) => {
    return async () => {
        try {
            console.log(body);
            const res = await API.PutService(getDesignation + "/" + id, body);
            if (res.data.success) {
                return { success: true, data: res.data.data };
            } else {
                return res.data;
            }
        } catch (error) {
            return { success: false };
        }
    }
}