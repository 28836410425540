/* eslint-disable import/no-anonymous-default-export */
export default {
  users: "users",
  googleURL: "api/auth/googleURL",
  loginUser: "api/auth/googleLogin",
  objectiveLevels: "api/objectives_level",
  getAllDepts: "api/department",
  // Multiselect component
  getAllUsers: "api/user",

  // Select dropdown data
  getAllCycles: "api/objectives_cycle",
  getAllStatus: "api/objectives_status",

  //goals
  crudGoals: "api/goal",
  // Objective
  crudObjective: "api/objectives",
  getObjective: "api/objectives/home",
  //update goals
  updateAchievementStatus: "api/goal/update/achievement_status",
  updateGoalScore: "api/goal/update/score",
  updateObjStatus: "api/objectives/update/status",

  editKeyResult: "api/objectives/update/key_result",
  editOverAllKeyResult: "api/objectives/update/overall_key_result",

  getQuarter: '/api/quarter',
  getQuarterSheet: '/api/quarter_sheet',
  getQuarterEmployee: '/api/quarter_employee',

  getCapability: '/api/capability',

  // Department
  getDepartment: '/api/department',

  getDesignation: '/api/designation',

  // staticData
  getStaticData: '/api/static_data?fields=',
};
