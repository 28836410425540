import {createAsyncThunk} from "@reduxjs/toolkit";
import API from "../../../api/ApiService";
import {setAuthToken} from "../../../helpers/apiConfig";
import {StoreUserData} from "../../../helpers/Utils";
import APIConstants from "../../../shared/constants/APIConstants";
import {setUserPermissionsReducer} from "../../reducers";
import {fetch_error, fetch_start, fetch_success, show_message,} from "../../reducers/CommonReducer";

const baseURL = `${process.env.REACT_APP_PUBLIC_URL}`;

export const getGoogleURL = createAsyncThunk(
    "createGoogleUrl",
    async (_, thunkAPI) => {
        thunkAPI.dispatch(fetch_start());
        const res = await API.getService(APIConstants.googleURL, {
            redirect_uri: baseURL,
        });

        if (res.data.success) {
            thunkAPI.dispatch(fetch_success());
        } else {
            thunkAPI.dispatch(fetch_error(res.data.message));
        }
        return res.data.data;
    }
);

export const Login = createAsyncThunk("LoginUser", async (body, thunkAPI) => {
    try {
        thunkAPI.dispatch(fetch_start());
        const res = await API.PostService(APIConstants.loginUser, {
            ...body,
            redirect_uri: baseURL,
        });

        if (res.data.success) {
            thunkAPI.dispatch(fetch_success());
            thunkAPI.dispatch(show_message(res.data.message));
            StoreUserData(res.data.data);
            thunkAPI.dispatch(setUserPermissionsReducer(res.data.data));
            setAuthToken(res.data.data.token);
        } else {
            thunkAPI.dispatch(fetch_error(res.data.message));
        }
        return res.data;
    } catch (error) {
        thunkAPI.dispatch(fetch_error(error));
        return {success: false, message: error};
    }
});
