import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import * as yup from "yup";
import DropDown from "../../../../../shared/ui/DropDown";

import { useNavigate } from "react-router-dom";
import { toCreateCapability, toGetDepartment } from "../../../../../redux/actions";
import InputField from "../../../../../shared/ui/InputField";
import LabelComponent from "../../../../../shared/ui/Label";
import CustomModal from "../../../../../shared/ui/Modal";

const CreateCapabilityModal = ({ showModal, setShowModal, usedFor }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [deptList, setDeptList] = useState([]);

    useEffect(async () => {
        const res = await dispatch(toGetDepartment());

        if (res.success) {
            const department = res.data.map((e) => ({ label: e.name, value: e.department_id }));

            setDeptList(department);
        }
    }, [dispatch])

    const validationSchema = yup.object({
        name: yup.string().required("Title is required"),
        department_id: yup.string().required("Department is required"),
    });
    const handleSubmit = async (values, { resetForm }) => {
        // console.log(values)
        const body = {
            name: values.name,
            sheet_data: '',
            department_id: values.department_id
        }
        const res = await dispatch(toCreateCapability(body));
        if (res.payload.success) {
            resetForm();
            setShowModal(false);
            navigate('/qpbvp/capabilities/' + res.payload.data.capability_id);
        }
    }
    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{
                name: '',
                department_id: "",
            }}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                setFieldValue,
                touched,
                handleChange,
                handleSubmit,
                handleReset,
            }) => {
                return (
                    <>
                        <CustomModal
                            show={showModal}
                            setShow={setShowModal}
                            title={`Create Capability`}
                            btnText2={"CREATE"}
                            btnText1={"DISCARD"}
                            size="lg"
                            usedFor={usedFor}
                            on_click_Done={handleSubmit}
                            on_click_button_one={() => {
                                handleReset();
                                setShowModal(false);
                            }}
                        >
                            <LabelComponent
                                title="Capability Title"
                                className={`mb-4`}
                            >
                                <InputField
                                    type="text"
                                    name={"name"}
                                    value={values.name}
                                    onChange={handleChange}
                                    placeHolder="Enter Title"
                                    errors={
                                        errors.name && touched.name && errors.name
                                    }
                                />
                            </LabelComponent>


                            {/* <LabelComponent
                                title="Choose a department"
                                className={`label-margin`}
                            >
                                <ToggleButtonGrpoupComponent
                                    radiobuttons={deptList}
                                    radiovalue={values.department}
                                    name="department"
                                    onChange={(e) =>
                                        setFieldValue(
                                            "department",
                                            e.target.value
                                        )
                                    }
                                />
                                {errors.department && touched.department && (
                                    <Form.Text className="error-text">
                                        {errors.department}
                                    </Form.Text>
                                )}
                            </LabelComponent> */}


                            {/* <div className="objective-dropdown">
                                <DropDown
                                    optionsList={deptList.label}
                                    label={"Choose a department"}
                                    value={deptList.value}
                                    name={"department_id"}
                                    onChange={(selectedValue) => {
                                        handleChange(selectedValue);
                                    }}
                                    errors={errors.department_id && touched.department_id && errors.department_id}
                                />
                            </div> */}
                            <DropDown
                                optionsList={deptList}
                                label={"Department"}
                                value={values.department_id}
                                name={"department_id"}
                                onChange={handleChange}
                                errors={
                                    errors.department_id &&
                                    touched.department_id &&
                                    errors.department_id
                                }
                            />

                        </CustomModal>
                    </>
                );
            }}
        </Formik>
    )
}

export default CreateCapabilityModal;