// This file have Dummy Data

// Owner profile pics
import SamImage from "../../assets/images/objective/owner_profile_images/Sam_Image.svg";
import AlokImage from "../../assets/images/objective/owner_profile_images/Alok_Image.svg";
import OmarImage from "../../assets/images/objective/owner_profile_images/Omar_Image.svg";
import AnkitaImage from "../../assets/images/objective/owner_profile_images/Ankita_Image.svg";

// Icons for toggl button group component
import Individual from "../../assets/images/objective/object_icons/Individual_Icon.svg";
import Department from "../../assets/images/objective/object_icons/Department_Icon.svg";
import Organistation from "../../assets/images/objective/object_icons/Organistation_Icon.svg";

//Icons for sidebar accordion profile
import Profile1 from "../../assets/images/profile-photo-1.png";
import Profile2 from "../../assets/images/profile-photo-2.png";
import Profile3 from "../../assets/images/profile-photo-3.png";
import Profile4 from "../../assets/images/profile-photo-4.png";
import Profile5 from "../../assets/images/profile-photo-5.png";
import Profile6 from "../../assets/images/profile-photo-6.png";

//dummy data for side bar people list and reportee list
export const List = [
  {
    id: 1,
    name: "Alok John",
    imgSrc: Profile1,
    path: "/people",
  },
  {
    id: 2,
    name: "Nilesh Kumar",
    imgSrc: Profile2,
    path: "/people",
  },
  {
    id: 3,
    name: "Vinumon",
    imgSrc: Profile3,
    path: "/people",
  },
  {
    id: 4,
    name: "Dilip Sagar",
    imgSrc: Profile4,
    path: "/people",
  },
  {
    id: 5,
    name: "Prabhasa",
    imgSrc: Profile5,
    path: "/people",
  },
  {
    id: 6,
    name: "Shailaja",
    imgSrc: Profile6,
    path: "/people",
  },
];

export const goalsList = [
  {
    id: 1,
    name: "Alok John",
    imgSrc: Profile1,
    path: "/goals/reportee",
  },
  {
    id: 2,
    name: "Nilesh Kumar",
    imgSrc: Profile2,
    path: "/goals/reportee",
  },
  {
    id: 3,
    name: "Vinumon",
    imgSrc: Profile3,
    path: "/goals/reportee",
  },
  {
    id: 4,
    name: "Dilip Sagar",
    imgSrc: Profile4,
    path: "/goals/reportee",
  },
  {
    id: 5,
    name: "Prabhasa",
    imgSrc: Profile5,
    path: "/goals/reportee",
  },
  {
    id: 6,
    name: "Shailaja",
    imgSrc: Profile6,
    path: "/goals/reportee",
  },
];

// Data for multiselect component
export const MultiselectForOwneroptions = [
  {
    value: 1,
    label: "Shailaja",
    backgroundColor: "rgba(255, 238, 240, 1)",
    ownerImage: null,
  },
  {
    value: 2,
    label: "Sam",
    backgroundColor: "rgba(233, 233, 255, 1)",
    ownerImage: SamImage,
  },
  {
    value: 3,
    label: "Alok",
    backgroundColor: "rgba(233, 255, 242, 1)",
    ownerImage: AlokImage,
  },
  {
    value: 4,
    label: "Omar",
    backgroundColor: "rgba(254, 208, 52, 0.12)",
    ownerImage: OmarImage,
  },
  {
    value: 5,
    label: "Ankita",
    backgroundColor: "rgba(255, 238, 240, 1)",
    ownerImage: AnkitaImage,
  },
];

// Data for Modified select component
export const optionsForStatusSelect = [
  { value: 1, label: "All" },
  { value: 2, label: "On track", iconColor: "#00E6AE" },
  { value: 3, label: "At risk", iconColor: "#FA7373" },
  { value: 4, label: "Not begun", iconColor: "#F8F9FC" },
  { value: 5, label: "Delayed", iconColor: "#FDC629" },
];

export const optionsForCycleSelect = [
  { value: 1, label: "Active cycle", isDisabled: true },
  { value: 2, label: "Quarter 3 - 2021-22" },
  { value: 3, label: "Quarter 2 - 2021-22" },
  { value: 4, label: "Quarter 1 -  2021-22" },
  { value: 5, label: "previous cycle", isDisabled: true },
  { value: 6, label: "2020-2019" },
  { value: 7, label: "2020-2019" },
  { value: 8, label: "2020-2019" },
];

// Data for toggle radio button group component
export const defaultRadios = [
  { name: "Individual", value: 1, icon: Individual },
  { name: "Department", value: 2, icon: Department },
  { name: "Organistation", value: 3, icon: Organistation },
];

// Data for toggle radio button group component
export const defaultRadios2 = [
  { name: "Only me", value: 1, icon: Individual },
  { name: "Department", value: 2, icon: Department },
  { name: "Organistation", value: 3, icon: Organistation },
];

export const defaultRadioswithoutIcon = [
  { name: "HR Team", value: 11 },
  { name: "Sales Team", value: 22 },
  { name: "Marketing Team", value: 23 },
  { name: "Delievery Team", value: 4 },
];

// Timeline data for Accordion
export const timelineData = [
  {
    conten: "",
  },
  {
    content: "",
    ownersData: [
      {
        name: "Sam",
        image: SamImage,
        backgroundColor: "rgba(233, 233, 255, 1)",
      },
      { name: "Alok", image: "", backgroundColor: "rgba(233, 255, 242, 1)" },
      { name: "Omar", image: "", backgroundColor: "rgba(254, 208, 52, 0.12)" },
    ],
  },
  {
    content: "",
    ownersData: [
      {
        name: "Sam",
        image: SamImage,
        backgroundColor: "rgba(233, 233, 255, 1)",
      },
      { name: "Alok", image: "", backgroundColor: "rgba(233, 255, 242, 1)" },
      { name: "Omar", image: "", backgroundColor: "rgba(254, 208, 52, 0.12)" },
      {
        name: "Ankita",
        image: "",
        backgroundColor: "background: rgba(255, 238, 240, 1)",
      },
      { name: "Sonu", image: "" },
    ],
  },
];

//options List
export const optionsList = [
  { value: 2, label: "Quarter 3 - 2021-22" },
  { value: 3, label: "Quarter 2 - 2021-22" },
  { value: 4, label: "Quarter 1 -  2021-22" },
];

//table data
export const tableHeaderData = ["GOAL", "ACHIEVEMENT STATUS", "SCORE"];

export const tableBodyData = [
  {
    goal: "Complete a case Study",
    achievementStatusText: "Achieved",
    achievementStatus: true,
    score: "8/10",
  },
  {
    goal: "Complete a case Study",
    achievementStatusText: "Achieved",
    achievementStatus: true,
    score: "7/10",
  },
  {
    goal: "Complete a case Study",
    achievementStatusText: "Not Achieved",
    achievementStatus: false,
    score: "9/10",
  },
  {
    goal: "Complete a case Study",
    achievementStatusText: "Not Achieved",
    achievementStatus: false,
    score: "4/10",
  },
];
