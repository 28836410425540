import APIConstants from "../../../shared/constants/APIConstants";
import API from "../../../api/ApiService";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetch_error, fetch_start, fetch_success,} from "../../reducers/CommonReducer";

const {
    getAllUsers,
    getAllCycles,
    getAllStatus,
    objectiveLevels,
    getAllDepts,
    getQuarter,
    getStaticData,
} = APIConstants;

// We can write common actions here

// To get all the user data
export const toGetAllUsers = () => {
    return async () => {
        try {
            const res = await API.getService(getAllUsers);
            if (res.data.success) {
                // const usersList = res.data.data.map((user) => ({
                //   value: user.user_id,
                //   label: user.first_name,
                // }));
                return {success: true, data: res.data.data};
            } else {
                return res.data;
            }
        } catch (error) {
            return {success: false};
        }
    };
};

export const toCreateUsers = (body) => {
    return async () => {
        try {
            const res = await API.PostService(getAllUsers, body);
            if (res.data.success) {
                return {success: true, data: res.data.data};
            } else {
                return res.data;
            }
        } catch (error) {
            return {success: false};
        }
    };
};
export const toUpdateUser = (body) => {
    return async () => {
        try {
            const res = await API.PutService(getAllUsers, body);
            if (res.data.success) {
                return {success: true, data: res.data.data};
            } else {
                return res.data;
            }
        } catch (error) {
            return {success: false};
        }
    };
};


export const toGetAllObjectiveLevels = () => {
    return async () => {
        try {
            const res = await API.getService(objectiveLevels);
            if (res.data.success) {
                const ObjLvlList = res.data.data.map((obj) => ({
                    value: obj.objectives_level_id,
                    label: obj.level,
                }));
                return {success: true, data: ObjLvlList};
            } else {
                return res.data;
            }
        } catch (error) {
            return {success: false};
        }
    };
};

export const toGetAllCycleData = () => {
    return async () => {
        try {
            const res = await API.getService(getAllCycles);
            if (res.data.success) {
                const cyclesList = res.data.data.map((cycle) => ({
                    value: cycle.objectives_cycle_id,
                    label: cycle.objectives_cycle_label,
                    status: cycle.objectives_status.status,
                }));
                return {success: true, data: cyclesList};
            } else {
                return res.data;
            }
        } catch (error) {
            return {success: false};
        }
    };
};

export const toGetAllDeptList = () => {
    return async () => {
        try {
            const res = await API.getService(getAllDepts);
            if (res.data.success) {
                const deptList = res.data.data.map((dept) => ({
                    value: dept.department_id,
                    label: dept.name,
                }));
                return {success: true, data: deptList};
            } else {
                return res.data;
            }
        } catch (error) {
            return {success: false};
        }
    };
};

// To get all Status data
export const toGetAllStatusList = createAsyncThunk(
    "getObjectiveStatus",
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());

            const res = await API.getService(getAllStatus);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                const statusList = res.data.data.map((status) => ({
                    value: status.objectives_status_id,
                    label: status.status,
                    iconColor: status.color,
                }));
                return {success: true, data: statusList};
            } else {
                thunkAPI.dispatch(fetch_error());

                return res.data;
            }
        } catch (error) {
            thunkAPI.dispatch(fetch_error());

            return {success: false};
        }
    }
);

export const toGetAllUsersList = createAsyncThunk(
    "getAllUsers",
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.getService(getAllUsers);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                return res.data;
            } else {
                thunkAPI.dispatch(fetch_error());
                return res.data;
            }
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error));
            return {success: false, message: error};
        }
    }
);

export const toGetAllAsyncDeptList = createAsyncThunk(
    "getAllDepartments",
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.getService(getAllDepts);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                return res.data;
            } else {
                thunkAPI.dispatch(fetch_error());
                return res.data;
            }
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error));
            return {success: false, message: error};
        }
    }
);

export const toGetAllQuarter = createAsyncThunk(
    "getAllQuarter",
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(fetch_start());
            const res = await API.getService(getQuarter);
            if (res.data.success) {
                thunkAPI.dispatch(fetch_success());
                return res.data;
            } else {
                thunkAPI.dispatch(fetch_error());
                return res.data;
            }
        } catch (error) {
            thunkAPI.dispatch(fetch_error(error));
            return {success: false, message: error};
        }
    }
);

export const toGetQuarterById = (quraterId) => {
    return async () => {
        try {
            const res = await API.getService(getQuarter + '/' + quraterId);
            if (res.data.success) {
                return {success: true, data: res.data.data};
            } else {
                return res.data;
            }
        } catch (error) {
            return {success: false};
        }
    };
};

export const toGetStaticData = (fieldsArray) => {
    return async () => {
        try {
            const res = await API.getService(getStaticData + fieldsArray);
            if (res.data.success) {
                return {success: true, data: res.data.data};
            } else {
                return res.data;
            }
        } catch (error) {
            return {success: false};
        }
    };
};