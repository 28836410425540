// We can write common functions and methods here
import {Cookies} from "react-cookie";
import React from "react";
import SalesIcon from "../../assets/images/sales-icon.svg";
import ActiveSalesIcon from "../../assets/images/active-sales-icon.svg";
import MarketIcon from "../../assets/images/marketing-icon.svg";
import ActiveMarketIcon from "../../assets/images/active-marketing-icon.svg";
import DelieveryIcon from "../../assets/images/delievery-icon.svg";
import ActiveDelieveryIcon from "../../assets/images/active-delievery-icon.svg";
// import { useDispatch } from "react-redux";
import {REVIEWER_PERMISSION, SUPER_ADMIN_PERMISSIONS, USER_PERMISSIONS,} from "../../shared/constants/CommonDataSet";
import CryptoJS from 'crypto-js';

const secretKey = '#aafIjas,15';

export const setToken = (token) => {
    const cookies = new Cookies();
    cookies.set("token", token);
};

export const getToken = () => {
    const cookies = new Cookies();
    const JWTtoken = cookies.get("token");
    return JWTtoken;
};

export const removeToken = () => {
    const cookies = new Cookies();
    cookies.remove("token");
};

export const Storage = {
    setData: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
    getData: (key) => {
        const data = localStorage.getItem(key);
        try {
            return JSON.parse(data);
        } catch (error) {
            return data;
        }
    },
    removeData: () => {
        localStorage.removeItem("setupData", null);
        localStorage.removeItem("is_login", false);
        removeToken();
        return true;
    },
};

export const StoreUserData = (data) => {
    const infoWithoutToken = {...data}; // Create a copy of the info object
    delete infoWithoutToken.token;
    Storage.setData("is_login", true);
    Storage.setData("setupData", encryptData(infoWithoutToken, secretKey));
};

export const getUserData = () => {
    const data = Storage.getData("setupData");
    return decryptData(data, secretKey);
};

// encrypt
const encryptData = (data, secretKey) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

// Decryption function
const decryptData = (encryptedData, secretKey) => {
    if (encryptedData !== null) {

        const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
    }
};
// onlogin success we will call this function to set the user permissons in the redux and then we can access it from anywhere

export const setUserPermissions = (role) => {
    switch (role.user_level) {
        case "Super Admin":
            const userRole = SUPER_ADMIN_PERMISSIONS;
            if (role.hasOwnProperty("has_qpbvp")) {
                userRole.hasQpbvp = role.has_qpbvp;
            }
            return userRole;
        case "Reviewer":
            return REVIEWER_PERMISSION;
        case "User":
            return USER_PERMISSIONS;
        default:
            return USER_PERMISSIONS;
    }
};

// to get respective objective_level_id according to pages
export const toGetObjectiveLevelForPage = (value, name) => {
    let objectives_level_id = null;
    value &&
    value.forEach((element) => {
        const {value, label} = element;
        if (label === name) {
            objectives_level_id = value;
        }
    });
    return objectives_level_id;
};

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
};

export const colorArray = [
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
];
// To get departemnt id
export const toGetDepartmentId = (value, name) => {
    let department_id = null;
    value.forEach((element) => {
        const {value, label} = element;
        if (label === name) {
            department_id = value;
        }
    });
    return department_id;
};

export const deptIconList = [
    {active: ActiveDelieveryIcon, inActive: DelieveryIcon},
    {active: ActiveMarketIcon, inActive: MarketIcon},
    {active: ActiveSalesIcon, inActive: SalesIcon},
    {active: ActiveDelieveryIcon, inActive: DelieveryIcon},
    {active: ActiveMarketIcon, inActive: MarketIcon},
    {active: ActiveSalesIcon, inActive: SalesIcon},
    {active: ActiveDelieveryIcon, inActive: DelieveryIcon},
    {active: ActiveMarketIcon, inActive: MarketIcon},
    {active: ActiveSalesIcon, inActive: SalesIcon},
];

// To filter current quarter
export const toFilterCurrentQuarter = (quaterList) => {
    // 0-2 3-5 6-8 9-11
    const d = new Date();
    let month = d.getMonth();
    if (month >= 0 && month <= 2) return quaterList[3];
    else if (month >= 3 && month <= 5) return quaterList[0];
    else if (month >= 6 && month <= 8) return quaterList[1];
    else if (month >= 9 && month <= 11) return quaterList[2];
};
