import { createSlice } from "@reduxjs/toolkit";

import { toCreateObjective, toGetAllObjectiveData } from "../../actions";

const okrsReducer = createSlice({
  name: "objectives", // This name will includes in Action name

  // Initial Value
  initialState: {
    objective: [],
    isLogin: false,
  },

  // it contains Async actions
  extraReducers: {
    [toGetAllObjectiveData.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.objective = [...action.payload.data];
        state.isLogin = true;
      }
    },
  },
});

export default okrsReducer.reducer;
