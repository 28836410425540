/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { useLocation } from "react-router";

import InputField from "../../../../shared/ui/InputField";
import LabelComponent from "../../../../shared/ui/Label";
import CustomModal from "../../../../shared/ui/Modal";
import MultiSelect from "../../../../shared/ui/MultiSelect";
import ToggleButtonGrpoupComponent from "../../../../shared/ui/ToggleButtonGroup";
import DropDown from "../../../../shared/ui/DropDown";

import "./style.css";
import {
  toCreateObjective,
  toGetAllCycleData,
  toGetAllDeptList,
  toGetAllObjectiveData,
  toGetAllObjectiveLevels,
  toGetAllUsers,
} from "../../../../redux/actions";
import KeyResult from "./KeyResults";
import { toGetObjectiveLevelForPage } from "../../../../helpers/Utils";

export default function AddObjective({ showModal, setShowModal, usedFor }) {
  const {
    userPermissions: { userType },
    user,
  } = useSelector(({ AuthUserReducer }) => AuthUserReducer);
  const dispatch = useDispatch();

  const { objectivesCycleId, objectivesStatusId } = useSelector(
    ({ CommonReducer }) => CommonReducer
  );

  const { pathname } = useLocation();
  const idd = pathname.split("/");
  const pathObjId = !isNaN(idd[idd.length - 1]) && idd[idd.length - 1];
  const pathObjName = idd[idd.length - 2] || idd[idd.length - 1] || "/";

  const [ownersList, setOwnersList] = useState([]);
  const [objLevelsList, setObjLevelsList] = useState([]);
  const [objCycleList, setObjCycleList] = useState([]);
  const [deptList, setDeptList] = useState([]);

  const validationSchema = yup.object({
    objective: yup.string().required("objective is required"),
    owners: yup.array().min(1).required("at least one item needs to be here"),
    objectivesLevel: yup.string().required("objective Level is required"),
    seeObjectivesLevel: yup
      .string()
      .required("See objective Level is required"),
    startDate: yup.string().required("Start date is required"),
    endDate: yup.string().required("end date is required"),
    objectivesCycle: yup.string().required("objective cycle is required"),
    keyResults: yup.array().of(
      yup.object().shape({
        keyResult: yup.string().required("key result is required"),
        owners: yup
          .array()
          .required("key result owners are required")
          .min(1, "please select atleast one owner"),
        showCustomResults: yup.bool(),
        customKeyResult: yup.array().of(
          yup.object().shape({
            startValue: yup.number().required("required*"),
            endValue: yup.number().required("required*"),
            quantification: yup.string().required("required*"),
          })
        ),
      })
    ),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const objOwnerIds =
      userType === "individual"
        ? values.owners
        : values.owners.map((owner) => ({
            user_id: owner.value,
          }));

    // const indiownerIds =

    const keyResultData = values.keyResults.map((keyResult) => {
      return {
        objective: keyResult.keyResult,
        // objectives_status_id: 7,
        key_result_owner_quantification: keyResult.customKeyResult.map(
          (customkey, i) => ({
            owner_user_id:
              userType === "individual" ? user.user_id : customkey.ownerId,
            objective_quantification: customkey.quantification,
            description: customkey.desc || keyResult.keyResult,
            start_value: customkey.startValue,
            end_value: customkey.endValue,
            progress: 0,
            objectives_status_id: 7,
          })
        ),
      };
    });
    const body = {
      objective: values.objective,
      objectives_level_id: values.objectivesLevel,
      see_objectives_level_id: values.seeObjectivesLevel,
      start_date: values.startDate,
      end_date: values.endDate,
      objectives_status_id: 7,
      objectives_cycle_id: values.objectivesCycle,
      key_result: keyResultData,
      objectives_department: values.objectivesDepartmentId
        ? [{ department_id: values.objectivesDepartmentId || null }]
        : [],
      objectives_owner: objOwnerIds,
    };
    const res = await dispatch(toCreateObjective(body));
    if (res.payload.success) {
      setTimeout(async () => {
        if (pathObjName === "department-objectives") {
          await dispatch(
            toGetAllObjectiveData({
              department_id: pathObjId,
              objectives_cycle_id: objectivesCycleId?.value,
              objectives_status_id: objectivesStatusId?.value,
            })
          );
        } else if (pathObjName == "/") {
          let objectiveList = await dispatch(toGetAllObjectiveLevels());

          let objectives_level_id = await toGetObjectiveLevelForPage(
            objectiveList.data,
            "Organization"
          );

          await dispatch(
            toGetAllObjectiveData({
              objectives_level_id,
              objectives_cycle_id: objectivesCycleId?.value,
              objectives_status_id: objectivesStatusId?.value,
            })
          );
        } else {
          await dispatch(
            toGetAllObjectiveData({
              user_id: pathObjId || user.user_id,
              objectives_cycle_id: objectivesCycleId?.value,
              objectives_status_id: objectivesStatusId?.value,
            })
          );
        }
      }, [800]);

      resetForm();
      setShowModal(false);
    }
  };

  useEffect(async () => {
    const ownersRes = await dispatch(toGetAllUsers());
    if (ownersRes.success) {
      setOwnersList(ownersRes.data);
    }

    const objLevelRes = await dispatch(toGetAllObjectiveLevels());
    if (objLevelRes.success) {
      setObjLevelsList(objLevelRes.data);
    }

    const objCycleRes = await dispatch(toGetAllCycleData());
    if (objCycleRes.success) {
      setObjCycleList(objCycleRes.data);
    }

    const deptList = await dispatch(toGetAllDeptList());
    if (deptList.success) {
      setDeptList(deptList.data);
    }
  }, []);

  const handleOwners = (data, setValue) => {
    setValue("owners", data);
  };

  const handleGoalCycleChange = (e, setFieldValue) => {
    const tempList = [...objCycleList];

    const QuarterValue = tempList
      .filter((data) => data.value == e.target.value)[0]
      .label.split(" ")[1];
    // 2022-04-15
    switch (QuarterValue) {
      case "1": {
        setFieldValue("startDate", `${new Date().getFullYear()}-04-01`);
        setFieldValue("endDate", `${new Date().getFullYear()}-06-30`);
        break;
      }
      case "2": {
        setFieldValue("startDate", `${new Date().getFullYear()}-07-01`);
        setFieldValue("endDate", `${new Date().getFullYear()}-09-30`);
        break;
      }
      case "3": {
        setFieldValue("startDate", `${new Date().getFullYear()}-10-01`);
        setFieldValue("endDate", `${new Date().getFullYear()}-12-31`);
        break;
      }
      case "4": {
        setFieldValue(
          "startDate",
          `${Number(new Date().getFullYear() + 1)}-01-01`
        );
        setFieldValue(
          "endDate",
          `${Number(new Date().getFullYear() + 1)}-03-31`
        );
        break;
      }
    }

    setFieldValue("objectivesCycle", e.target.value);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        objective: "",
        owners: userType === "individual" ? [{ user_id: user.user_id }] : [],
        individualPeopleList: [],
        objectivesLevel: (userType === "individual" && "1") || "",
        seeObjectivesLevel: (userType === "individual" && "1") || "",
        objectivesDepartmentId: "",
        seeObjectivesDepartmentId: "",
        startDate: "",
        endDate: "",
        objectivesCycle: "",
        keyResults: [
          {
            keyResult: "",
            owners:
              userType === "individual" ? [{ user_id: user.user_id }] : [],
            customKeyResult: [
              { startValue: "", endValue: "", quantification: "" },
            ],
            showCustomResults: false,
            isEditable: true,
            isExpanded: true,
          },
        ],
      }}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        setFieldValue,
        touched,
        handleChange,
        handleSubmit,
        handleReset,
      }) => {
        return (
          <>
            <CustomModal
              show={showModal}
              setShow={setShowModal}
              title={`Add an Objective`}
              btnText2={"CREATE AN OBJECTIVE"}
              btnText1={"DISCARD"}
              size="lg"
              usedFor={usedFor}
              on_click_Done={handleSubmit}
              on_click_button_one={() => {
                handleReset();
                setShowModal(false);
              }}
            >
              <LabelComponent
                title="What is the Objective?"
                className={`label-margin`}
              >
                <InputField
                  type="text"
                  name={"objective"}
                  value={values.objective}
                  onChange={handleChange}
                  placeHolder="Describe your objective"
                  errors={
                    errors.objective && touched.objective && errors.objective
                  }
                />
              </LabelComponent>
              {userType !== "individual" && (
                <LabelComponent
                  title="Who is the Owner?"
                  className={`label-margin`}
                >
                  <MultiSelect
                    selectOptions={ownersList}
                    name="owners"
                    onSelect={(data) => handleOwners(data, setFieldValue)}
                    errors={errors.owners && touched.owners && errors.owners}
                  />
                </LabelComponent>
              )}

              {userType !== "individual" && (
                <>
                  <LabelComponent
                    title="Who is this objective for?"
                    className={`label-margin`}
                  >
                    <ToggleButtonGrpoupComponent
                      permissionsBased
                      radiobuttons={objLevelsList}
                      radiovalue={values.objectivesLevel}
                      usedFor="object"
                      name={"objectivesLevel"}
                      onChange={(e) =>
                        setFieldValue("objectivesLevel", e.target.value)
                      }
                    />
                    {errors.objectivesLevel && touched.objectivesLevel && (
                      <Form.Text className="error-text">
                        {errors.objectivesLevel}
                      </Form.Text>
                    )}
                  </LabelComponent>

                  {values.objectivesLevel === "3" ? (
                    <LabelComponent
                      title="Choose a department"
                      className={`label-margin`}
                    >
                      <ToggleButtonGrpoupComponent
                        radiobuttons={deptList}
                        radiovalue={values.objectivesDepartmentId}
                        name="objectivesDepartmentId"
                        onChange={(e) =>
                          setFieldValue(
                            "objectivesDepartmentId",
                            e.target.value
                          )
                        }
                      />
                    </LabelComponent>
                  ) : (
                    (values.objectivesLevel === "4" ||
                      values.objectivesLevel === "4") &&
                    ""
                  )}
                </>
              )}

              <LabelComponent
                title="When does this objective start and end?"
                className={`label-margin`}
              >
                <div className="d-flex class-dropdown">
                  <div className="objective-dropdown">
                    <DropDown
                      optionsList={objCycleList}
                      label={"CHOOSE GOAL CYCLE"}
                      value={values.objectivesCycle}
                      name={"objectivesCycle"}
                      onChange={(e) => handleGoalCycleChange(e, setFieldValue)}
                      errors={
                        errors.objectivesCycle &&
                        touched.objectivesCycle &&
                        errors.objectivesCycle
                      }
                    />
                  </div>
                  <div className="date-dropdown d-flex">
                    <div className={"input-margin"}>
                      <InputField
                        type="date"
                        value={values.startDate}
                        name={"startDate"}
                        onChange={handleChange}
                        label={"START DATE"}
                        style={{ cursor: "pointer" }}
                        errors={
                          errors.startDate &&
                          touched.startDate &&
                          errors.startDate
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        type="date"
                        value={values.endDate}
                        name="endDate"
                        onChange={handleChange}
                        label={"END DATE"}
                        style={{ cursor: "pointer" }}
                        errors={
                          errors.endDate && touched.endDate && errors.endDate
                        }
                      />
                    </div>
                  </div>
                </div>
              </LabelComponent>

              {userType !== "individual" && (
                <>
                  <LabelComponent
                    title="Who can see this objective?"
                    className={`label-margin`}
                  >
                    <ToggleButtonGrpoupComponent
                      radiobuttons={objLevelsList}
                      radiovalue={values.seeObjectivesLevel}
                      usedFor="object"
                      name={"seeObjectivesLevel"}
                      onChange={(e) =>
                        setFieldValue("seeObjectivesLevel", e.target.value)
                      }
                    />

                    {errors.seeObjectivesLevel &&
                      touched.seeObjectivesLevel && (
                        <Form.Text className="error-text">
                          {errors.seeObjectivesLevel}
                        </Form.Text>
                      )}
                  </LabelComponent>

                  {values.seeObjectivesLevel === "3" && (
                    <LabelComponent
                      title="Choose a department"
                      className={`label-margin`}
                    >
                      <ToggleButtonGrpoupComponent
                        radiobuttons={deptList}
                        radiovalue={values.seeObjectivesDepartmentId}
                        name="seeObjectivesDepartmentId"
                        onChange={(e) =>
                          setFieldValue(
                            "seeObjectivesDepartmentId",
                            e.target.value
                          )
                        }
                      />
                    </LabelComponent>
                  )}
                </>
              )}

              <KeyResult
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                ownersList={ownersList}
                objCycleList={objCycleList}
              />
            </CustomModal>
          </>
        );
      }}
    </Formik>
  );
}
