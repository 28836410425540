/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import InputField from "../../../../../shared/ui/InputField";
import DropDown from "../../../../../shared/ui/DropDown";
import CustomModal from "../../../../../shared/ui/Modal";
import { toCreateUsers } from "../../../../../redux/actions";

const CreateUser = ({ showModal, setShowModal, usedFor, ogFilterData, handleOnSave }) => {
  const dispatch = useDispatch();

  const [departmentId, setDepartmentId] = useState("");
  const [designationId, setDesignationId] = useState("");

  const reviewerOptions = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 }
  ];

  const [designationOptionsRaw, setDesignationOptions] = useState([]);

  const validationSchema = yup.object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    employee_id: yup.string().required("Employee ID is required"),
    email: yup.string().required("Email is required"),
    department_id: yup.string().required("Department is required"),
    designation_id: yup.string().required("Designation is required"),
    user_level_id: yup.string().required("User level is required"),
  });

  // Clear designation when department is changed
  useEffect(() => {
    setDesignationId("");
  }, [departmentId]);

  // Clear state when dialog box is opened 
  useEffect(() => {
    setDepartmentId("");
    setDesignationId("");
  }, [showModal]);

  const handleChangeDepartment = (selectedValue) => {
    setDepartmentId(selectedValue.target.value);
    const filteredDesignations = ogFilterData.designation.filter(
      (option) => option.department_id === parseInt(selectedValue.target.value)
    );

    setDesignationOptions(filteredDesignations);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const res = await dispatch(toCreateUsers(values));
    console.log('res',res)

    if (res.success) {
      resetForm();
      setShowModal(false);
      handleOnSave();
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        first_name: "",
        last_name: "",
        employee_id: "",
        email: "",
        department_id: "",
        designation_id: "",
        user_level_id: "",
      }}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        setFieldValue,
        touched,
        handleChange,
        handleSubmit,
        handleReset,
      }) => (
        <>
          <CustomModal
            show={showModal}
            setShow={setShowModal}
            title={`Create User`}
            btnText2={"CREATE"}
            btnText1={"DISCARD"}
            size="lg"
            usedFor={usedFor}
            on_click_Done={handleSubmit}
            on_click_button_one={() => {
              handleReset();
              setShowModal(false);
            }}
          >
            <Row className="mb-3">
              <Col>
                <Form.Label className={"select-label"}>First Name</Form.Label>
                <InputField
                  type="text"
                  name={"first_name"}
                  value={values.first_name}
                  onChange={handleChange}
                  placeHolder=""
                  errors={errors.first_name && touched.first_name && errors.first_name}
                />
              </Col>
              <Col>
                <Form.Label className={"select-label"}>Last Name</Form.Label>
                <InputField
                  type="text"
                  name={"last_name"}
                  value={values.last_name}
                  onChange={handleChange}
                  placeHolder=""
                  errors={errors.last_name && touched.last_name && errors.last_name}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Label className={"select-label"}>Employee Id</Form.Label>
                <InputField
                  type="text"
                  name={"employee_id"}
                  value={values.employee_id}
                  onChange={handleChange}
                  placeHolder=""
                  errors={errors.employee_id && touched.employee_id && errors.employee_id}
                />
              </Col>
              <Col>
                <Form.Label className={"select-label"}>Email</Form.Label>
                <InputField
                  type="text"
                  name={"email"}
                  value={values.email}
                  onChange={handleChange}
                  placeHolder=""
                  errors={errors.email && touched.email && errors.email}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div className="objective-dropdown">
                  <DropDown
                    optionsList={ogFilterData?.department}
                    label={"Department"}
                    value={departmentId}
                    name={"department_id"}
                    onChange={(selectedValue) => {
                      handleChange(selectedValue);
                      handleChangeDepartment(selectedValue);
                    }}
                    errors={errors.department_id && touched.department_id && errors.department_id}
                  />
                </div>
              </Col>
              <Col>
                <div className="objective-dropdown">
                  <DropDown
                    optionsList={designationOptionsRaw}
                    label={"Designation"}
                    value={designationId}
                    name={"designation_id"}
                    onChange={(selectedValue) => {
                      handleChange(selectedValue);
                      setDesignationId(selectedValue.target.value);
                    }}
                    errors={errors.designation_id && touched.designation_id && errors.designation_id}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="objective-dropdown">
                  <DropDown
                    optionsList={ogFilterData?.user_level}
                    label={"User Level"}
                    value={values.user_level_id}
                    name={"user_level_id"}
                    onChange={handleChange}
                    errors={errors.user_level_id && touched.user_level_id && errors.user_level_id}
                  />
                </div>
              </Col>
              <Col>
              </Col>
            </Row>
          </CustomModal>
        </>
      )}
    </Formik>
  );
};

export default CreateUser;
