import React from "react";
import {Route, Routes} from "react-router-dom";
import AuthRoutes from "../../routes/AuthRoutes";

export default function AuthLayout() {
    const Loading = () => <div className="text-center">Loading...</div>;
    return (
        <>
            <React.Suspense fallback={<Loading/>}>
                <Routes>
                    {AuthRoutes.map((route, i) => (
                        <Route
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            element={route.component}
                            key={i}
                        />
                    ))}
                </Routes>
            </React.Suspense>
        </>
    );
}
