import React, {useEffect, useState} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";

import "./style.scss";
import ThenceLogo from "../../assets/images/header/Thence_logo.svg";

import DeliveryIcon from "../../assets/images/delievery-icon.svg";
import DeliveryIconActive from "../../assets/images/active-delievery-icon.svg";
import {useLocation, useNavigate} from "react-router";
import {getUserData, Storage} from "../../helpers/Utils";
import {updateAuthUser} from "../../redux/reducers";

const HeaderComponent = () => {
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const onChangeTab = async (value) => {
        switch (value) {
            case 'OKRs':
                navigate("/");
                break;
            case 'QPBVP':
                navigate("/qpbvp/quarter")
                break;
            case 'MY-QPBVP':
                navigate("/my-qpbvp")
                break;
            case 'My-Resources':
                navigate("/my-resources")
                break;
            default:
                navigate("/goals");
                break;
        }
    };
    const handleGoToHome = () => {
        navigate("/qpbvp/quarter");
    };

    const {
        userPermissions: {userType, hasQpbvp},
    } = useSelector(({AuthUserReducer}) => AuthUserReducer);


    useEffect(() => {
        const userData = getUserData();
        setUserData(userData)
        // console.log(location.pathname)
    }, []);

    const logout = async () => {
        let res = Storage.removeData();
        if (res) {
            if (location.pathname !== "") navigate("/");
            await dispatch(updateAuthUser({data: null, isLogin: false}));
        }
    };

    return (
        <>
            <Navbar className="header pb-0 pt-0 pe-3">
                <Container className="container">
                    <div className="logo-class">
                        <Navbar.Brand
                            className="p-0 m-0 d-flex"
                            style={{cursor: "pointer"}}
                            onClick={handleGoToHome}
                        >
                            <img src={ThenceLogo} alt="Logo"/>
                        </Navbar.Brand>
                    </div>
                    <div className="nav-class">
                        <Nav className="me-auto">

                            {userType !== "user" &&
                                <Nav.Link
                                    onClick={() => onChangeTab("QPBVP")}
                                    active={location.pathname.includes("/qpbvp")}
                                >
                                    <span className="links">
                                      <>
                                        {!location.pathname.includes("qpbvp") ? (
                                            <img src={DeliveryIcon} alt="DeliveryIcon"/>
                                        ) : (
                                            <img src={DeliveryIconActive} alt="DeliveryIcon"/>
                                        )}
                                      </>
                                      <span className="link-name">
                                        QPBVP
                                      </span>
                                    </span>
                                </Nav.Link>
                            }

                            {
                                (userType === "user" || (userType === 'super_admin' && hasQpbvp)) && (
                                    <Nav.Link
                                        onClick={() => onChangeTab("MY-QPBVP")}
                                        active={location.pathname.includes("my-qpbvp")}
                                    >
                                      <span className="links">
                                        {!location.pathname.includes("my-qpbvp") ? (
                                            <img src={DeliveryIcon} alt="DeliveryIcon"/>
                                        ) : (
                                            <img src={DeliveryIconActive} alt="DeliveryIcon"/>
                                        )}
                                          <span className="link-name">My QPBVP</span>
                                      </span>
                                    </Nav.Link>
                                )
                            }


                            {/*{userData?.is_reviewer &&*/}
                            {/*    <Nav.Link*/}
                            {/*        onClick={() => onChangeTab("My-Resources")}*/}
                            {/*        active={location.pathname.includes("my-resources")}*/}
                            {/*    >*/}
                            {/*        <span className="links">*/}
                            {/*          <>*/}
                            {/*            {!location.pathname.includes("my-resources") ? (*/}
                            {/*                <img src={MarketingIcon} alt="DeliveryIcon"/>*/}
                            {/*            ) : (*/}
                            {/*                <img src={MarketingIcon} alt="DeliveryIcon"/>*/}
                            {/*            )}*/}
                            {/*          </>*/}
                            {/*          <span className="link-name">*/}
                            {/*            My Resources*/}
                            {/*          </span>*/}
                            {/*        </span>*/}
                            {/*    </Nav.Link>*/}
                            {/*}*/}

                            {/*<Nav.Link*/}
                            {/*    className="personal_growth"*/}
                            {/*    onClick={() => onChangeTab("OKRs")}*/}
                            {/*  active={!location.pathname.includes("goals")}*/}
                            {/*>*/}
                            {/*  <div className="links">*/}
                            {/*    <div>*/}
                            {/*      {!location.pathname.includes("goals") ? (*/}
                            {/*        <img src={OKRIocnBlue} alt="OKR_Iocn" />*/}
                            {/*      ) : (*/}
                            {/*        <img src={OKRIocnGrey} alt="OKR_Iocn" />*/}
                            {/*      )}*/}
                            {/*    </div>*/}
                            {/*    <div className="link-name">*/}
                            {/*      <div>OKRs</div>*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                            {/*</Nav.Link>*/}


                            {/*<Nav.Link*/}
                            {/*  className="personal_growth"*/}
                            {/*  onClick={() => onChangeTab("PERSONAL_GROWTH")}*/}
                            {/*  active={location.pathname.includes("goals")}*/}
                            {/*>*/}
                            {/*  <div className="links">*/}
                            {/*    <div>*/}
                            {/*      {location.pathname.includes("goals") ? (*/}
                            {/*        <img*/}
                            {/*          src={PersonalGrowthIconBlue}*/}
                            {/*          alt="personal_growth_iocn"*/}
                            {/*        />*/}
                            {/*      ) : (*/}
                            {/*        <img*/}
                            {/*          src={PersonalGrowthIconGrey}*/}
                            {/*          alt="personal_growth_iocn"*/}
                            {/*        />*/}
                            {/*      )}*/}
                            {/*    </div>*/}
                            {/*    <div className="link-name">*/}
                            {/*      <div>Personal Growth and Goals</div>*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                            {/*</Nav.Link>*/}
                        </Nav>
                    </div>
                </Container>
                <div className="logout-class" onClick={() => logout()}>
                    <span className="logout-text-class">Logout</span>
                </div>
            </Navbar>
        </>
    );
};

export default HeaderComponent;
