import { cleanup } from "@testing-library/react";
import React, { useState, useEffect } from "react";
import {
  ButtonGroup,
  ToggleButton,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import RightTick from "../../../assets/images/objective/object_icons/Right_tick_Icon.svg";
import HollowCircle from "../../../assets/images/objective/object_icons/Hollow_circle.svg";
import Individual from "../../../assets/images/objective/object_icons/Individual_Icon.svg";
import Department from "../../../assets/images/objective/object_icons/Department_Icon.svg";
import Organistation from "../../../assets/images/objective/object_icons/Organistation_Icon.svg";

import "./style.css";
import { useSelector } from "react-redux";

const Card = ({ data, type, selectedButton, icon }) => {
  const { label } = data;
  if (type === "object" || type === "objective") {
    return (
      <Container className="common-height-class">
        <Row style={{ height: "103px" }}>
          <Col className=" common-height-class center-alignment-class">
            <div>
              <div>
                <img src={icon} alt={`${label}_icon`} />
              </div>
              <div className="toggle-button-card-class pt-2">{label}</div>
            </div>
          </Col>
          <Col xm={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
            <div
              style={{
                visibility: `${selectedButton === false ? "hidden" : ""}`,
              }}
            >
              <img src={RightTick} alt={`tick_icon`} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container>
          <Row className="">
            <Col style={{ display: "flex" }}>
              <div className="toggle-button-card-class">{label}</div>
            </Col>
            <Col xm={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
              <div>
                {selectedButton === true ? (
                  <img src={RightTick} alt={`tick_icon`} />
                ) : (
                  <img src={HollowCircle} alt={`tick_icon`} />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

const iconsList = [Individual, Department, Organistation];

const ToggleButtonGrpoupComponent = (props) => {
  const {
    radiobuttons,
    size,
    radiovalue,
    onChange,
    // setradiovalue = () => {},
    style,
    spacing = "0.5rem",
    usedFor,
    className,
    permissionsBased,
    ...rest
  } = props;

  const { userPermissions, user } = useSelector(
    ({ AuthUserReducer }) => AuthUserReducer
  );
  const [radios, setRadios] = useState(radiobuttons);

  useEffect(() => {
    setRadios(radiobuttons);
    return cleanup();
  }, [radiobuttons]);

  const CustomToggleButton = ({ radio, idx }) => (
    <ToggleButton
      {...rest}
      key={idx}
      id={uuidv4()}
      type="radio"
      value={radio.value}
      checked={radiovalue === radio.value}
      onChange={onChange}
      style={{
        ...style,
        zIndex: "auto",
        border: `${
          parseInt(radiovalue) === parseInt(radio.value)
            ? "1px solid #00f"
            : "1px solid #ddd"
        }`,
        marginLeft: `${idx !== 0 && spacing}`,
      }}
      className={`toggle-button-class ${className} mb-2`}
    >
      <Card
        icon={iconsList[idx]}
        data={radio}
        type={usedFor}
        selectedButton={parseInt(radiovalue) === parseInt(radio.value)}
      />
    </ToggleButton>
  );

  return (
    <>
      <ButtonGroup size={size} style={{ width: "100%", flexWrap: "wrap" }}>
        {permissionsBased
          ? userPermissions.OrganizationCrudPermissions &&
            userPermissions.DepartmentCrudPermissions
            ? radios.map((radio, idx) => (
                <CustomToggleButton radio={radio} idx={idx} key={idx} />
              ))
            : userPermissions.DepartmentCrudPermissions &&
              radios
                .slice(0, 2)
                .map((radio, idx) => (
                  <CustomToggleButton radio={radio} idx={idx} key={idx} />
                ))
          : radios.map((radio, idx) => {
              if (userPermissions.userType === "dept_head") {
                if (usedFor === "object" || usedFor === "objective")
                  return (
                    <CustomToggleButton radio={radio} idx={idx} key={idx} />
                  );
                else {
                  if (user.department_id === radio.value) {
                    return (
                      <CustomToggleButton radio={radio} idx={idx} key={idx} />
                    );
                  } else {
                    return null;
                  }
                }
              } else {
                return <CustomToggleButton radio={radio} idx={idx} key={idx} />;
              }
            })}
      </ButtonGroup>
    </>
  );
};

export default ToggleButtonGrpoupComponent;
