import React from "react";

const SignIn = React.lazy(() => import("../modules/auth/SignIn"));

const AuthRoutes = [
  {
    path: "/*",
    name: "SignIn",
    component: <SignIn />,
    exact: true,
  },
];

export default AuthRoutes;
