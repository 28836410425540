/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { useDispatch  } from "react-redux";
import { Formik } from "formik";

import InputField from "../../../../../shared/ui/InputField";
import CustomModal from "../../../../../shared/ui/Modal";
import { toCreateQuarter, toGetAllQuarter } from "../../../../../redux/actions";
import { useNavigate } from "react-router-dom";

const CreateQuarter = ({ showModal, setShowModal, usedFor }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = yup.object({
        quarter_title: yup.string().required("Quarter title is required"),
        quarter_start_date: yup.string().required("Start date is required"),
        quarter_end_date: yup.string().required("End date is required"),
        review_start_date: yup.string().required("Review start date is required"),
        review_end_date: yup.string().required("Review end date is required"),
        employee_input_start_date: yup.string().required("Employee input start date is required"),
        employee_input_end_date: yup.string().required("Employee input end date is required"),
    });

    const handleSubmit = async (values, { resetForm }) => {
        const res = await dispatch(toCreateQuarter(values));
        if (res.payload.success) {
            await dispatch(toGetAllQuarter());
            resetForm();
            setShowModal(false);
            navigate('/qpbvp/quarter/' + res.payload.data.quarter_id);
        }
    }
    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{
                quarter_title: '',
                quarter_start_date: '',
                quarter_end_date: '',
                review_start_date: '',
                review_end_date: '',
                employee_input_start_date: '',
                employee_input_end_date: '',
            }}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                setFieldValue,
                touched,
                handleChange,
                handleSubmit,
                handleReset,
            }) => {
                return (
                    <>
                        <CustomModal
                            show={showModal}
                            setShow={setShowModal}
                            title={`Create Quarter`}
                            btnText2={"CREATE"}
                            btnText1={"DISCARD"}
                            size="lg"
                            usedFor={usedFor}
                            on_click_Done={handleSubmit}
                            on_click_button_one={() => {
                                handleReset();
                                setShowModal(false);
                            }}
                        >

                            <Form.Label className="input-label" >
                                {"Quarter Title"}
                            </Form.Label>

                            <Row className="mb-3">
                                <InputField
                                    type="text"
                                    name={"quarter_title"}
                                    value={values.quarter_title}
                                    onChange={handleChange}
                                    placeHolder="Enter Quarter Title"
                                    errors={
                                        errors.quarter_title && touched.quarter_title && errors.quarter_title
                                    }
                                />
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    <InputField
                                        type="date"
                                        value={values.quarter_start_date}
                                        name={"quarter_start_date"}
                                        onChange={handleChange}
                                        label={"Start Date"}
                                        style={{ cursor: "pointer" }}
                                        errors={
                                            errors.quarter_start_date &&
                                            touched.quarter_start_date &&
                                            errors.quarter_start_date
                                        }
                                    />
                                </Col>
                                <Col>
                                    <InputField
                                        type="date"
                                        value={values.quarter_end_date}
                                        name="quarter_end_date"
                                        onChange={handleChange}
                                        label={"End Date"}
                                        style={{ cursor: "pointer" }}
                                        errors={
                                            errors.quarter_end_date && touched.quarter_end_date && errors.quarter_end_date
                                        }
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    <InputField
                                        type="date"
                                        value={values.review_start_date}
                                        name={"review_start_date"}
                                        onChange={handleChange}
                                        label={"Review Start Date"}
                                        style={{ cursor: "pointer" }}
                                        errors={
                                            errors.review_start_date &&
                                            touched.review_start_date &&
                                            errors.review_start_date
                                        }
                                    />
                                </Col>
                                <Col>
                                    <InputField
                                        type="date"
                                        value={values.review_end_date}
                                        name="review_end_date"
                                        onChange={handleChange}
                                        label={"Review End Date"}
                                        style={{ cursor: "pointer" }}
                                        errors={
                                            errors.review_end_date && touched.review_end_date && errors.review_end_date
                                        }
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    <InputField
                                        type="date"
                                        value={values.employee_input_start_date}
                                        name={"employee_input_start_date"}
                                        onChange={handleChange}
                                        label={"Employee Input Start Date"}
                                        style={{ cursor: "pointer" }}
                                        errors={
                                            errors.employee_input_start_date &&
                                            touched.employee_input_start_date &&
                                            errors.employee_input_start_date
                                        }
                                    />
                                </Col>
                                <Col>
                                    <InputField
                                        type="date"
                                        value={values.employee_input_end_date}
                                        name="employee_input_end_date"
                                        onChange={handleChange}
                                        label={"Employee Input End Date"}
                                        style={{ cursor: "pointer" }}
                                        errors={
                                            errors.employee_input_end_date && touched.employee_input_end_date && errors.employee_input_end_date
                                        }
                                    />
                                </Col>
                            </Row>

                        </CustomModal>
                    </>
                );
            }}
        </Formik>
    )
}

export default CreateQuarter;