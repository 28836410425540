import { Form } from "react-bootstrap";
import "./dropdown.css";

export default function DropDown({
  optionsList,
  label,
  value,
  onChange,
  errors,
  className,
  ...rest
}) {
  return (
    <Form.Group>
      {label && <Form.Label className={"select-label"}>{label}</Form.Label>}
      <div className="select">
        <Form.Select
          className={`select-class ${className} ${
            errors && "drop-error-class"
          }`}
          value={value}
          onChange={onChange}
          {...rest}
        >
          <option disabled hidden value="">
            Select
          </option>
          {optionsList &&
            optionsList.map((option, i) => (
              <option value={option.value} key={i} className={option.label}>
                {option.label}
              </option>
            ))}
        </Form.Select>
      </div>
      {errors && <Form.Text className="error-text">{errors}</Form.Text>}
    </Form.Group>
  );
}
