import { Modal } from "react-bootstrap";
import ButtonComponent from "../Button";
import "./modal.css";

export default function CustomModal(props) {
    const {
        title,
        show,
        setShow,
        children,
        btnText1,
        btnText2,
        size,
        centered,
        closeButton,
        className,
        usedFor,
        hideFooter,
        on_click_Done,
        on_click_button_one,
        ...rest
    } = props;

    return (
        <Modal
            {...rest}
            show={show}
            size={size}
            onHide={() => on_click_button_one()}
            // backdrop="static"
            keyboard={false}
            centered={centered}
            dialogClassName={className}
        >
            <Modal.Header
                className="d-flex justify-content-center align-items-center"
                closeButton={closeButton}
            >
                <Modal.Title className="title-class">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${usedFor ? "body-class" : "px-4"}`}>
                {children}
            </Modal.Body>
            {!hideFooter &&
                <Modal.Footer className={`${usedFor ? "modal-footer-class" : ""}`}>
                    <span>
                        {btnText1 && (
                            <ButtonComponent
                                className="modal-footer-btn1 px-4 py-2"
                                onClick={() => on_click_button_one()}
                            >
                                {btnText1}
                            </ButtonComponent>
                        )}

                        {btnText2 && (
                            <ButtonComponent
                                type="submit"
                                className="modal-footer-btn2 px-4 py-2"
                                onClick={() => on_click_Done()}
                            >
                                {btnText2}
                            </ButtonComponent>
                        )}
                    </span>
                </Modal.Footer>
            }
        </Modal>
    );
}
