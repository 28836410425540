import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../api/ApiService";
import APIConstants from "../../../shared/constants/APIConstants";
import {
  fetch_start,
  fetch_success,
  fetch_error,
  show_message,
} from "../../reducers/CommonReducer";

const {
  crudObjective,
  updateObjStatus,
  editKeyResult,
  editOverAllKeyResult,
  getObjective,
} = APIConstants;

export const toGetAllObjectiveData = createAsyncThunk(
  "Objectives",
  async (params, thunkAPI) => {
    thunkAPI.dispatch(fetch_start());
    const res = await API.getService(getObjective, { ...params });
    if (res.data.success) {
      thunkAPI.dispatch(fetch_success());
    } else {
      thunkAPI.dispatch(fetch_error(res.data.message));
    }
    return res.data;
  }
);

export const toCreateObjective = createAsyncThunk(
  "createObj",
  async (body, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.PostService(crudObjective, body);
      if (res.data.success) {
        thunkAPI.dispatch(fetch_success());
        thunkAPI.dispatch(show_message(res.data.message));
      } else {
        thunkAPI.dispatch(fetch_error(res.data.message));
      }
      return res.data;
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error.message));
      return { success: false, message: error.message };
    }
  }
);

export const toEditObjective = createAsyncThunk(
  "editObj",
  async (body, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.PutService(crudObjective, body);
      if (res.data.success) {
        thunkAPI.dispatch(fetch_success());
        thunkAPI.dispatch(show_message(res.data.message));
      } else {
        thunkAPI.dispatch(fetch_error(res.data.message));
      }
      return res.data;
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error.message));
      return { success: false, message: error.message };
    }
  }
);

// To Update Objective status
export const toUpdateObjectiveStatus = createAsyncThunk(
  "updateObjStatus",
  async (body, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.PutService(updateObjStatus, body);
      if (res.data.success) {
        thunkAPI.dispatch(fetch_success());
        thunkAPI.dispatch(show_message(res.data.message));
      } else {
        thunkAPI.dispatch(fetch_error(res.data.message));
      }
      return res.data;
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error.message));
      return { success: false, message: error.message };
    }
  }
);

// To delete an Objective
export const toDeleteObjective = createAsyncThunk(
  "updateScore",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.deleteService(crudObjective, {
        byId: true,
        id: id,
      });
      if (res.data.success) {
        thunkAPI.dispatch(fetch_success());
        thunkAPI.dispatch(show_message(res.data.message));
        return res.data;
      } else {
        thunkAPI.dispatch(fetch_error(res.data.message));
        return res.data;
      }
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error));
      return { success: false, message: error };
    }
  }
);

export const toEditKeyResultStatus = createAsyncThunk(
  "editKeyResultStatus",
  async (body, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.PutService(editKeyResult, body);
      if (res.data.success) {
        thunkAPI.dispatch(fetch_success());
        // thunkAPI.dispatch(show_message(res.data.message));
      } else {
        thunkAPI.dispatch(fetch_error(res.data.message));
      }
      return res.data;
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error.message));
      return { success: false, message: error.message };
    }
  }
);

export const toEditOverAllKeyResultStatus = createAsyncThunk(
  "editOverAllKeyResultStatus",
  async (body, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.PutService(editOverAllKeyResult, body);
      if (res.data.success) {
        thunkAPI.dispatch(fetch_success());
        // thunkAPI.dispatch(show_message(res.data.message));
      } else {
        thunkAPI.dispatch(fetch_error(res.data.message));
      }
      return res.data;
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error.message));
      return { success: false, message: error.message };
    }
  }
);
