import React, { useState } from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import "./inputfield.css";

export default function InputField({
  label,
  value,
  type,
  onChange,
  disabled,
  className,
  id,
  readOnly,
  style,
  placeHolder,
  name,
  errors,
}) {
  const [tempValue, setTempValue] = useState("");
  const handleChange = (e) => setTempValue(e.target.value);
  return (
    <Form.Group>
      {label && (
        <Form.Label className="input-label" htmlFor={id}>
          {label || ""}
        </Form.Label>
      )}
      <Form.Control
        type={type}
        value={value || tempValue}
        onChange={onChange || handleChange}
        disabled={disabled}
        name={name}
        className={` ${!errors ? "input-class" : "error-class"} ${className} `}
        id={id}
        readOnly={readOnly}
        style={{ ...style }}
        placeholder={placeHolder}
        autoComplete="off"
      />
      {errors && <Form.Text className="error-text">{errors}</Form.Text>}
    </Form.Group>
  );
}

InputField.defaultProps = {
  label: "",
  type: "",
  value: "",
  onChange: () => {},
  disabled: false,
  className: "",
  id: "",
  readOnly: false,
  style: {},
  name: "",
  placeHolder: "",
};
InputField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  placeHolder: PropTypes.string,
};
