import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import AuthUser from "../reducers/AuthUser";
import CommonReducer from "../reducers/CommonReducer";
import Goals from "../reducers/Goals";
import Quarter from "../reducers/Quarter";
// import usersReducer from "../reducers/usersReducer.js";
import objectiveReducer from "../reducers/OKRs";

const reducers = configureStore({
  // It contains different reducers
  reducer: {
    objectiveReducerData: objectiveReducer,
    AuthUserReducer: AuthUser,
    CommonReducer: CommonReducer,
    GoalsReducer: Goals,
    QuarterReducer: Quarter
  },

  //   You can use your own middleware
  middleware: [...getDefaultMiddleware()],
});

export default reducers;
