import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { Form, Row, Col } from "react-bootstrap";
import { MultiselectForOwneroptions } from "../../../shared/constants/DummyData";
import "./style.css";

const chipBackgroundColors = [
  "rgba(255, 238, 240, 1)",
  "rgba(233, 233, 255, 1)",
  "rgba(233, 255, 242, 1)",
  "rgba(254, 208, 52, 0.12)",
  "rgba(255, 238, 250, 1)",
  "rgb(168, 255, 194)",
  "rgba(255, 238, 240, 1)",
  "rgba(233, 233, 255, 1)",
  "rgba(233, 255, 242, 1)",
  "rgba(254, 208, 52, 0.12)",
  "rgba(255, 238, 250, 1)",
  "rgb(168, 255, 194)",
  "rgba(255, 238, 240, 1)",
  "rgba(233, 233, 255, 1)",
  "rgba(233, 255, 242, 1)",
  "rgba(254, 208, 52, 0.12)",
  "rgba(255, 238, 250, 1)",
  "rgb(168, 255, 194)",
  "rgba(255, 238, 240, 1)",
  "rgba(233, 233, 255, 1)",
  "rgba(233, 255, 242, 1)",
  "rgba(254, 208, 52, 0.12)",
  "rgba(255, 238, 250, 1)",
  "rgb(168, 255, 194)",
];

const selectStyle = {
  control: (base) => ({
    ...base,
    height: "60px",
    boxShadow: "none",
    borderRadius: "0px",
    border: "1px solid #e8e9ed",
    // overflowY: "scroll",
    backgroundColor: "#FFFFFF",

    "& > div": {
      backgroundColor: "#FFFFFF",
    },
  }),

  multiValueLabel: (base) => ({
    ...base,
    color: "#101F47",
    fontSize: "14px",
  }),
  multiValue: (base, state) => {
    return {
      ...base,
      backgroundColor: state.data.color,
      color: "#101F47",
      height: "30px",
      borderRadius: "14px",
      padding: "4px 8px 4px 0px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: `${state.index !== 0 && "16px"}`,
      "& > div": {
        display: "flex",
        alignItems: "center",
        height: "30px",
        padding: "0px",
        "& > span": {
          alignSelf: "center",
          fontSize: "14px",
          paddingRight: "5px",
        },
      },
    };
  },
  multiValueRemove: (base) => ({
    ...base,
    "&:hover": {
      backgroundColor: "transparent",
      color: "#101F47",
      cursor: "pointer",
    },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: "#FFFFFF",
    color: "black",
    "&:hover": {
      backgroundColor: state.data.color,
      color: "black",
    },
  }),
};

const MultiValue = (props) => {
  return (
    <components.MultiValue {...props}>
      <div
        className="profile-pic-class-for-selected-options"
        style={{
          backgroundColor: `${!props.data.ownerImage && "red"}`,
        }}
      >
        {props.data.ownerImage ? (
          <img src={props.data.ownerImage} alt="Profile_Image" />
        ) : (
          <span className="label">{props.data.label[0]}</span>
        )}
      </div>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};

const MultiSelect = (props) => {
  const {
    value,
    defaultValue,
    isClearable,
    selectOptions,
    onSelect,
    selectAllOption,
    errors,
  } = props;
  const [selectedOption, setSelectedOption] = useState(defaultValue || null);
  const [selectOptionsWithColor, setSelectOptionsWithColor] = useState();

  useEffect(() => {
    const finalOptions = selectOptions.map((data, i) => ({
      ...data,
      color: chipBackgroundColors[i],
    }));

    setSelectOptionsWithColor(finalOptions);
  }, [selectOptions]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelect(selectedOption);
  };

  useEffect(() => {
    if (value) {
      const finalValue = value.map((data, i) => ({
        ...data,
        color: chipBackgroundColors[i],
      }));
      setSelectedOption(finalValue);
    } else {
      setSelectedOption(value);
    }
  }, [value]);

  return (
    <div style={{ width: "100%", alignContent: "center" }}>
      <Row className="mb-1">
        <Form.Group
          as={Col}
          xs={selectAllOption ? 10 : 12}
          className="mb-0"
          controlId="multi-select"
        >
          <Select
            {...props}
            className={`${errors ? "multi-select-error-class" : ""}`}
            value={selectedOption || value}
            onChange={handleChange}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={true}
            isClearable={isClearable || false}
            options={selectOptionsWithColor || MultiselectForOwneroptions}
            components={{
              MultiValue,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            styles={{
              ...selectStyle,
              // backgroundColor: "red",
            }}
          />
          {errors && <Form.Text className="error-text">{errors}</Form.Text>}
        </Form.Group>
      </Row>
    </div>
  );
};

export default MultiSelect;
