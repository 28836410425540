import React from "react";
import { Button } from "react-bootstrap";
import "./button.css";

const ButtonComponent = (props) => {
  const { onClick, children, className } = props;
  const onSubmit = () => {
    return;
  };

  return (
    <Button
      {...props}
      className={`${className}`}
      onClick={onClick || onSubmit}
    >
      {children}
    </Button>
  );
};

export default ButtonComponent;
