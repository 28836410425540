import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../api/ApiService";
import APIConstants from "../../../shared/constants/APIConstants";


import {
    fetch_start,
    fetch_success,
    fetch_error,
    show_message,
} from "../../reducers/CommonReducer";

const {
    getDepartment,
} = APIConstants

export const toGetDepartment = () => {
    return async () => {
        try {
            const res = await API.getService(getDepartment);
            if (res.data.success) {
                return { success: true, data: res.data.data };
            } else {
                return res.data;
            }
        } catch (error) {
            return { success: false };
        }
    }
}

export const toCreateDepartment = (body)=> {
    return async () => {
        try {
            console.log(body);
            const res = await API.PostService(getDepartment,body);
            if (res.data.success) {
                return { success: true, data: res.data.data };
            } else {
                return res.data;
            }
        } catch (error) {
            return { success: false };
        }
    }
}

export const toUpdateDepartment = (body)=> {
    return async () => {
        try {
            console.log(body);
            const res = await API.PutService(getDepartment,body);
            if (res.data.success) {
                return { success: true, data: res.data.data };
            } else {
                return res.data;
            }
        } catch (error) {
            return { success: false };
        }
    }
}