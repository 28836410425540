import React from "react";

const CompanyObjectives = React.lazy(() =>
    import("../modules/Dashboard/OKR/CompanyObjectives")
);
const DepartmentObjectives = React.lazy(() =>
    import("../modules/Dashboard/OKR/DepartmentObjectives")
);

const MyObjectives = React.lazy(() =>
    import("../modules/Dashboard/OKR/MyObjectives")
);
const People = React.lazy(() => import("../modules/Dashboard/OKR/People"));

const MyGoals = React.lazy(() => import("../modules/Dashboard/Goals/MyGoals"));
const MyReportees = React.lazy(() =>
    import("../modules/Dashboard/Goals/MyReportees")
);

// QPBVP
const Quarter = React.lazy(() =>
    import("../modules/Dashboard/QPBVP/Quarter")
);
const QuarterSingle = React.lazy(() =>
    import("../modules/Dashboard/QPBVP/Quarter/Single")
);
const QpbvpCapability = React.lazy(() =>
    import("../modules/Dashboard/QPBVP/Capability")
);
const QpbvpCapabilitySingle = React.lazy(() =>
    import("../modules/Dashboard/QPBVP/Capability/Single")
);

const QpbvpUser = React.lazy(() =>
    import("../modules/Dashboard/QPBVP/User")
);

const QpbvpDepartment = React.lazy(() =>
    import("../modules/Dashboard/QPBVP/Department")
)

const QpbvpDesignation = React.lazy(() =>
    import("../modules/Dashboard/QPBVP/Designation")
)
const MyQPBVP = React.lazy(() =>
    import("../modules/Dashboard/QPBVP/MY-QPBVP")
)

const SummaryView = React.lazy(() =>
    import("../modules/Dashboard/QPBVP/SummaryView")
)

const HomeComponent = React.lazy(() => import("../modules/Dashboard/HomeComponent"))
const MyQuarter = React.lazy(() => import("../modules/Dashboard/QPBVP/MY-QPBVP/MyQuarter"))
const EmployeeQPBVP = React.lazy(() => import("../modules/Dashboard/QPBVP/EmployeeQPBVP"))

// export const OKRRoutes = [
//   {
//     path: "/",
//     name: "COMPANY OBJECTIVES",
//     component: <CompanyObjectives />,
//     exact: true,
//     type: "item",
//     children: null,
//   },
//   {
//     path: "/department-objectives/:id",
//     name: "DEPARTMENT OBJECTIVES",
//     component: <DepartmentObjectives />,
//     exact: true,
//     type: "dept",
//     children: null,
//   },
//   {
//     path: "/my-objectives",
//     name: "MY OBJECTIVES",
//     component: <MyObjectives />,
//     exact: true,
//     type: "item",
//     children: null,
//   },
//   {
//     path: "/people/:id",
//     name: "PEOPLE",
//     component: <People />,
//     exact: true,
//     type: "item",
//     children: null,
//   },
// ];
//
// export const GoalRoutes = [
//   {
//     path: "/goals",
//     name: "MY GOALS",
//     component: <MyGoals />,
//     exact: true,
//     type: "item",
//     children: null,
//   },
//   {
//     path: "/goals/reportee/:id",
//     name: "MY REPORTEE",
//     component: <MyReportees />,
//     exact: true,
//     type: "item",
//     children: null,
//   },
// ];
// QPBVP Rouotes
export const QPBVPRoutes = [
    {
        path: "/",
        name: "Home",
        component: <HomeComponent/>,
        exact: true,
        type: "section",
        access: ['super_admin', 'reviewer', 'user'],
        children: [],
        sidebars: [],
    },

    {
        path: "/qpbvp/quarter",
        name: "QUARTER",
        component: <Quarter/>,
        exact: true,
        type: "section",
        sidebars: [
            {
                type: 'quarters',
                link: '/qpbvp/quarter'
            }
        ],
        children: [
            {
                path: "/qpbvp/quarter/:id",
                name: "QUARTER",
                component: <QuarterSingle/>,
                exact: true,
                type: "group",
                children: null,
                access: ['super_admin'],

            }
        ],
        access: ['super_admin'],
    },
    {
        path: "/qpbvp/summary-view",
        name: "SUMMARY VIEW",
        component: <SummaryView/>,
        exact: true,
        type: "item",
        children: [],
        access: ['super_admin'],
    },
    {
        path: "/qpbvp/capabilities",
        name: "CAPABILITIES",
        component: <QpbvpCapability/>,
        exact: true,
        type: "item",
        children: [
            {
                path: "/qpbvp/capabilities/:id",
                name: "CAPABILITIES",
                component: <QpbvpCapabilitySingle/>,
                exact: true,
                type: "group",
                children: null,
                access: ['super_admin'],
            }
        ],

        access: ['super_admin'],
    },

    {
        path: "/qpbvp/users",
        name: "USERS",
        component: <QpbvpUser/>,
        exact: true,
        type: "item",
        children: [],
        access: ['super_admin'],
    },
    {
        path: "/qpbvp/department",
        name: "DEPARTMENT",
        component: <QpbvpDepartment/>,
        exact: true,
        type: "item",
        children: [],
        access: ['super_admin'],
    },
    {
        path: "/qpbvp/designation",
        name: "DESIGNATION",
        component: <QpbvpDesignation/>,
        exact: true,
        type: "item",
        children: [],
        access: ['super_admin'],
    },
    {
        path: "/my-qpbvp",
        name: "My QPBVP",
        component: <MyQPBVP/>,
        exact: true,
        type: "section",
        children: [
            {
                path: "/my-qpbvp/quarter/:id",
                name: "Quarter",
                component: <MyQuarter/>,
                exact: true,
                type: "section",
                children: [],
                access: ['user'],
                sidebars: [],
            }, {
                path: "/my-qpbvp/quarter/:id/employee/:employee_id",
                name: "Quarter",
                component: <EmployeeQPBVP/>,
                exact: true,
                type: "section",
                children: [],
                access: ['reviewer'],
                sidebars: [],
            },
        ],
        access: ['reviewer', 'user'],
        sidebars: [
            {
                type: 'quarters',
                link: '/my-qpbvp/quarter'
            }
        ],
    },

]