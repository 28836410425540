import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../api/ApiService";
import APIConstants from "../../../shared/constants/APIConstants";
import {
  fetch_error,
  fetch_start,
  fetch_success,
  show_message,
} from "../../reducers/CommonReducer";

export const getAllGoalsList = createAsyncThunk(
  "getGoals",
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.getService(APIConstants.crudGoals, { ...params });
      if (res.data.success) {
        thunkAPI.dispatch(fetch_success());
        return res.data;
      } else {
        thunkAPI.dispatch(fetch_error());
        return res.data;
      }
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error));
      return { success: false, message: error };
    }
  }
);

export const toCreateGoal = createAsyncThunk(
  "createGoals",
  async (body, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.PostService(APIConstants.crudGoals, body);
      if (res.data.success) {
        thunkAPI.dispatch(fetch_success());
        thunkAPI.dispatch(show_message(res.data.message));
        return res.data;
      } else {
        thunkAPI.dispatch(fetch_error(res.data.message));
        return res.data;
      }
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error));
      return { success: false, message: error };
    }
  }
);

export const toEditGoal = createAsyncThunk(
  "editGoals",
  async (body, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.PutService(APIConstants.crudGoals, body);
      if (res.data.success) {
        thunkAPI.dispatch(fetch_success());
        thunkAPI.dispatch(show_message(res.data.message));
        return res.data;
      } else {
        thunkAPI.dispatch(fetch_error(res.data.message));
        return res.data;
      }
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error));
      return { success: false, message: error };
    }
  }
);

export const toUpdateAchievementStatus = createAsyncThunk(
  "updateAchiementStatus",
  async (body, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.PutService(
        APIConstants.updateAchievementStatus,
        body.body
      );
      if (res.data.success) {
        thunkAPI.dispatch(fetch_success());
        const { filterData } = body;
        thunkAPI.dispatch(getAllGoalsList({ user_id: body.id, ...filterData }));
        return res.data;
      } else {
        thunkAPI.dispatch(fetch_error(res.data.message));
        return res.data;
      }
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error));
      return { success: false, message: error };
    }
  }
);

export const toUpdateGoalScore = createAsyncThunk(
  "updateScore",
  async (body, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.PutService(APIConstants.updateGoalScore, body.body);
      if (res.data.success) {
        const { filterData } = body;
        thunkAPI.dispatch(fetch_success());
        thunkAPI.dispatch(getAllGoalsList({ user_id: body.id, ...filterData }));
        return res.data;
      } else {
        thunkAPI.dispatch(fetch_error(res.data.message));
        return res.data;
      }
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error));
      return { success: false, message: error };
    }
  }
);

export const toDeleteGoal = createAsyncThunk(
  "updateScore",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetch_start());
      const res = await API.deleteService(APIConstants.crudGoals, {
        byId: true,
        id: id,
      });
      if (res.data.success) {
        thunkAPI.dispatch(fetch_success());
        thunkAPI.dispatch(show_message(res.data.message));
        return res.data;
      } else {
        thunkAPI.dispatch(fetch_error(res.data.message));
        return res.data;
      }
    } catch (error) {
      thunkAPI.dispatch(fetch_error(error));
      return { success: false, message: error };
    }
  }
);
