import { createSlice } from "@reduxjs/toolkit";
import {toGetQuarter} from '../../actions'

const QuarterReducer = createSlice({
    name: "quarters",

    initialState: {
        quarter: [],
        loading: "",
    },
    extraReducers: {
        [toGetQuarter.fulfilled]: (state, action) => {
            // console.log(action)
            if (action.payload.success) {
                state.quarter = action.payload.data;
            }
        },
    },
});

export default QuarterReducer.reducer;
