import axios from "axios";
import {removeToken, setToken} from "../Utils";

const customAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

customAxios.interceptors.response.use(
    (res) => res,
    (err) => {
        if (
            err.response &&
            (err.response.data.msg === "Token is not valid" ||
                err.response.data.msg ===
                "You don't have permission to perform this action!")
        ) {
            removeToken();
        }
        return Promise.reject(err);
    }
);

export const setAuthToken = (token) => {
    if (token) {
        customAxios.defaults.headers.common["Authorization"] = token;
        setToken(token);
    } else {
        delete customAxios.defaults.headers.common["x-auth-token"];
        removeToken();
    }
};

export default customAxios;
