import React from "react";
import { Form } from "react-bootstrap";

import "./style.css";

const LabelComponent = (props) => {
  const { children, required, title, className } = props;
  return (
    <Form.Group className={className}>
      {title && (
        <Form.Label className="label-class">
          {title}
          {required && <span className="required-class">*</span>}
        </Form.Label>
      )}
      <div className="mt-2">{children}</div>
    </Form.Group>
  );
};

export default LabelComponent;
