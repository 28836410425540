import React from "react";
import {Accordion} from "react-bootstrap";
import {useLocation} from "react-router";
import Avatar from "@mui/material/Avatar";
import {colorArray, deptIconList, useDebounce} from "../../../helpers/Utils";

import "./style.css";
import Select from '@mui/material/Select';
import {MenuItem} from "@mui/material";
import {styled} from "@mui/material/styles";
import Chip from "@mui/material/Chip";

const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root {
    > .MuiSelect-select {
      padding: 0.2rem 1.75rem 0.2rem 0.5rem;
      font-size: 12px;
    }
  }
`;
const StyledChip = styled(Chip)`
  &.MuiChip-root {
    padding: 2px 0;
    background: #fff;
    border: 1px solid #4017be;
    color: #4017BEFF;
    font-size: 11px;
    height: auto;

    > span {
      overflow: visible;
    }
  }
`;
export default function SidebarAccordion({
                                             title,
                                             itemList,
                                             handleClick = () => {
                                             },
                                             hasSearch = false,
                                             path,
                                         }) {
    const currentLocation = useLocation();
    const [searchValue, setSearchValue] = React.useState("");
    const lastValue = useDebounce(searchValue, 500);
    const [usersList, setUsersList] = React.useState(itemList);
    const [isActive, setActive] = React.useState(false);
    const handleSearchChnage = (e) => {
        setSearchValue(e.target.value);
    };

    React.useEffect(() => {
        setUsersList(itemList);
        if (currentLocation.pathname.includes("qpbvp/quarter")) {
            setActive(true);
        }
        if (currentLocation.pathname.includes("people")) {
            setActive(true);
        }
    }, [itemList]);

    // React.useEffect(() => {
    //     const tempList = [...itemList];
    //     const filteredList = tempList.filter(
    //         (val) =>
    //             val.first_name?.toLowerCase()?.includes(lastValue.toLowerCase()) ||
    //             val.last_name?.toLowerCase()?.includes(lastValue.toLowerCase())
    //     );
    //     // if (filteredList.length > 0) {
    //     setUsersList(filteredList);
    //     // } else {
    //     //   setUsersList(itemList);
    //     // }
    // }, [lastValue]);


    return (
        <Accordion flush className="accord-class"
                   defaultActiveKey={currentLocation.pathname.includes("qpbvp/quarter") ? "1" : "0"}>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
          <span
              className={isActive && title === 'QUARTERS' ? 'active' : ''}
          >
            {title}
          </span>
                </Accordion.Header>
                <Accordion.Body className="show">
                    {title === "QUARTERS" &&
                        <StyledSelect
                            label="Year"
                            value={2023}
                        >
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                        </StyledSelect>
                    }

                    {usersList &&
                        usersList.map((child, i) => {
                            return (
                                <span className="list-item" key={i}>
                <div
                    className={`sidebar-dropdown`}
                    onClick={() => {
                        if (title === "PEOPLE" || title === "MY REPORTEES") {
                            handleClick(`${path}/${child.user_id}`, child);
                        } else if (title === "DEPARTMENT OBJECTIVES") {
                            handleClick(`${path}/${child.department_id}`, child);
                        } else {
                            handleClick(`${path}/${child.quarter_id}`, child);
                        }
                    }}
                >
                  {title !== "QUARTERS" &&
                      <>
                    <span className="icon-class me-2">
                    {title === "DEPARTMENT OBJECTIVES" && (
                        currentLocation.pathname ===
                        `${path}/${child.department_id}` ? (
                            <img
                                src={deptIconList[i].active}
                                alt="dept_icon_active"
                            />
                        ) : (
                            <img
                                src={deptIconList[i].inActive}
                                alt="dept_icon_inactive"
                            />
                        )
                    )}
                        {title === 'PEOPLE' && (
                            <Avatar
                                sx={{
                                    width: "25px",
                                    height: "25px",
                                    fontSize: "15px",
                                    backgroundColor: colorArray[i],
                                }}
                            >
                                {child?.first_name[0] || "A"}
                            </Avatar>
                        )}
                  </span>
                      </>
                  }

                    <span
                        className={`${
                            title === "QUARTERS"
                                ? currentLocation.pathname ===
                                `${path}/${child.quarter_id}`
                                    ? "active"
                                    : ""
                                : currentLocation.pathname ===
                                `${path}/${child.user_id}`
                                    ? "active"
                                    : ""
                        } accordion-sub`}
                    >
                    {title === 'PEOPLE' && (
                        <>
                            {
                                child.first_name
                                    ? `${child.first_name} ${child.last_name}`
                                    : child.name
                            }
                        </>
                    )
                    }
                        {title === 'QUARTERS' && (
                            <>
                                {child.quarter_title}
                                {child.is_active === 1 &&
                                    <StyledChip label={"On Going"}/>
                                }
                            </>
                        )}
                  </span>
                </div>
              </span>
                            )
                        })}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}
